import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import {get_contract} from "./rentaContractModalityTariff_controller";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {english} from "flatpickr/dist/l10n/default";
import {Russian} from "flatpickr/dist/l10n/ru";
import {French} from "flatpickr/dist/l10n/fr";
import {Italian} from "flatpickr/dist/l10n/it";
import {German} from "flatpickr/dist/l10n/de";
import {Portuguese} from "flatpickr/dist/l10n/pt";
import {MandarinTraditional} from "flatpickr/dist/l10n/zh-tw";
import {set_locale} from './localize_controller'
import {create_array_for_product, create_id_for_product} from "./b2b_controller";

let categories = [];
let active_categories = [];
let modality_descriptions = [];
let date_disabled = [];
let flatpick_lang;
let entity_value = '';
let category_value = '';
let lang_value = '';
let location_value = '';
let all_product = 'false';
let dealer_id = null;
let dealer_code = null;
let product_id = null;
let dealer_service_data = [];
let modalities_data = [];
let person_category_data = [];


export let preferences = (text, type) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: location.origin + `/${sessionStorage.getItem ('locale')}/config/get_preference`,
        data: {
            type: type,
            text: text
        },
        type: 'GET',
        success: function (data) {
            if (data.length > 0) {
                resolve (data[0]);
            }

        }
    })
})

let categories_details = (target) => new Promise ((resolve, reject) => {
    categories.services.map ((child, key) => {
        if (child !== null) {
            if (child.code === target) {
                resolve (child);
            }
        }
    });
})

export function modifiedKey (key) {
    let v = key;
    v = v.split ("_");
    let last = v.pop ();
    let nameCapitalized = last.charAt (0).toUpperCase () + last.slice (1);
    v.push (nameCapitalized);
    return v.join ('');
}

let disable_dates = () => new Promise ((resolve, reject) => {
    $.ajax ({
        url: location.origin + "/admin/disable_dates/disabled",
        data: {},
        type: "GET",
        success: function (data) {
            resolve (data);
        }
    })
})

let verify_season = (date) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: location.origin + "/verify_season",
        data: {
            date: date,
            category: sessionStorage.getItem ("category")
        },
        type: "GET",
        success: function (data) {
            if (data.success) {
                resolve (data.data);
            } else {
                console.log (data);
            }
        }

    })
})

let event_make_comment = () => {
    let description = '';
    // if (!category_value.isEmpty()){
        description += '-Categoría: '+category_value+'-';
    // }
    //
    // if(!entity_value.isEmpty()){
        description += '-Nombre de entidad: '+entity_value+'-';
    // }

    create_array_for_product ('comment', description);
}
export let modality_info = (dealer, product, modality_name, info = true, adults = 0, childrens = 0) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/modalities/info`,
        data: {
            dealer: dealer,
            product: product,
            modality: modality_name,
            info: info,
            adults: adults,
            childrens: childrens,
            all_product: all_product
        },
        type: 'GET',
        success: function (modality) {
            resolve (modality)
        }
    })
})
export default class extends Controller {
    static targets = ["siteName", "ticketLogo", "textDestination", "collectPlace", "category", "modality", "textSearchMark", "textSearchModel", "dateCollect", "timeCollect", "dateDelivery", "timeDelivery", "textButton", "siteNameFooter", "contactNumber", "contactNumberTwo", "contactEmail", "contactEmailTwo", "contactAddress", "staticPage", "formText", "formDescription", "formDescriptionPlaceholder"];

    connect () {
        set_locale ().then (
            locale => {

                disable_dates ().then (
                    dd => {
                        date_disabled = dd;
                    }
                )

                switch (I18n.locale) {
                    case 'es':
                        flatpick_lang = Spanish;
                        break;
                    case 'ru':
                        flatpick_lang = Russian;
                        break;
                    case 'fr':
                        flatpick_lang = French;
                        break;
                    case 'it':
                        flatpick_lang = Italian;
                        break;
                    case 'de':
                        flatpick_lang = German;
                        break;
                    case 'pt':
                        flatpick_lang = Portuguese;
                        break;
                    case 'ch':
                        flatpick_lang = MandarinTraditional;
                        break;
                    case 'en':
                        flatpick_lang = english;
                        break;
                }

                const context = this;
                let preferences = {};

                // PRODUCTOS EXCUERSIONES
                let excursion_to, excursion_date, excursion_type, excursion_pax_block, excursion_pax,
                    excursion_adult_childrens, excursion_adultos, excursion_childrens, excursion_cantidad_block,
                    excursion_cantidad, excursion_modality, form_type_send;
                excursion_to = $ ('#excursion_to');
                excursion_date = $ ('#excursion_date');
                excursion_type = $ ('#excursion_type');
                excursion_modality = $ ('#excursion_modality');
                let excursion_lang = $ ('#excursion_lang');
                excursion_pax_block = $ ('#excursion_pax_block');
                excursion_pax = $ ('#excursion_pax');
                excursion_cantidad_block = $ ('#excursion_cantidad_block');
                excursion_cantidad = $ ('#excursion_cantidad');
                excursion_adult_childrens = $ ('#excursion_adult_childrens');
                excursion_adultos = $ ('#excursion_adultos');
                excursion_childrens = $ ('#excursion_childrens');
                excursion_date.prop ('disabled', true);
                excursion_type.prop ('disabled', true).select2 ({theme: 'bootstrap'});
                excursion_modality.prop ('disabled', true).select2 ({theme: 'bootstrap'});
                excursion_pax.prop ('disabled', true).select2 ({theme: 'bootstrap'});
                form_type_send = $ ('#search_form_type')

                excursion_lang.select2 (
                    {
                        theme: 'bootstrap',
                        placeholder: 'Seleccione un idioma',
                        ajax: {
                            url: '/admin/country/get_json_data',
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    form_type: form_type_send.val (),
                                    search: params.term,
                                    page: params.page || 1
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;
                                data.map ((elem, key) => {
                                    result.push ({
                                        id: elem.id,
                                        text: elem.lang
                                    })
                                })

                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }
                ).on ('change', function (e) {
                    lang_value = e.target.textContent;
                    let text = '';
                    if (!location_value.isEmpty () && !lang_value.isEmpty ()) {
                        text = `Recogida en: ${location_value}, Idioma: ${e.target.textContent}`;
                    } else {
                        if (!location_value.isEmpty ()) {
                            text = `Recogida en: ${location_value}`;
                        }
                        if (!lang_value.isEmpty ()) {
                            text = `Idioma: ${e.target.textContent}`;
                        }
                    }
                    create_array_for_product ('comment', text);
                });

                excursion_to.select2 ({
                    theme: "bootstrap",
                    ajax: {
                        url: '/destination',
                        dataType: "json",
                        language: "es",
                        data: function (params) {
                            return {
                                form_type: form_type_send.val (),
                                search: params.term,
                                page: params.page || 1
                            }

                        },
                        type: 'GET',
                        processResults: function (data, params) {
                            let result = [];
                            params.page = params.page || 1;

                            data.rows.map ((elem, key) => {
                                result.push ({
                                    id: elem.code + '|' + elem.id + '|' + elem.name,
                                    text: elem.name
                                })
                            })

                            if (data.total > 10) {

                                if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": true
                                        }
                                    }
                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }

                            } else {
                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }
                }).on ('change', function (e) {
                    let data_excursion_to = e.target.value.split ("|")[1];
                    create_array_for_product ('product_type', form_type_send.val ());
                    create_array_for_product ('destination_id', e.target.value.split ("|")[1]);
                    create_array_for_product ('destination_code', e.target.value.split ("|")[0]);
                    create_array_for_product ('text_destination', e.target.value.split ("|")[2]);

                    excursion_type.empty ();
                    excursion_modality.empty ();

                    excursion_type.prop ('disabled', false).select2 ({
                        theme: "bootstrap",
                        ajax: {
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_dealer`,
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1,
                                    destination: data_excursion_to,
                                    form_type: form_type_send.val ()
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;
                                data.services.map ((elem, key) => {

                                    result.push ({
                                        id: elem.id + "|" + elem.code + "|" + elem.reservation_time + "|" + data.dealer_code + "|" + data.dealer_name + "|" + data.dealer_id + "|" + elem.min_reservation_amount + "|" + elem.max_reservation_amount,
                                        text: elem.name
                                    })
                                })

                                if (data.total > 10) {

                                    if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": true
                                            }
                                        }
                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }

                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }
                            }
                        }
                    }).on ('change', function (e) {

                        excursion_modality.empty ();
                        excursion_pax_block[0].classList.remove ('ocultar');
                        excursion_cantidad_block[0].classList.add ('ocultar');
                        excursion_adult_childrens[0].classList.add ('ocultar');

                        let product_type = e.target.value.split ("|");
                        let product_name = '';
                        product_name = e.target.selectedOptions[0].innerHTML.toLowerCase ();

                        let excursion_location_container = $ ('#excursion_location_container');
                        let excursion_location = $ ('#excursion_location');
                        let excursion_lang_container = $ ('#excursion_lang_container');
                        let excursion_lang = $ ('#excursion_lang');


                        if (product_name.includes ('bicicleta') || product_name.includes ('buggy')) {
                            excursion_location_container[0].classList.add ('ocultar');
                            excursion_location[0].classList.remove ('required');
                            excursion_lang_container[0].classList.add ('ocultar');
                            excursion_lang[0].classList.remove ('required');
                        } else {
                            excursion_location_container[0].classList.remove ('ocultar');
                            excursion_location[0].classList.add ('required');
                            excursion_lang_container[0].classList.remove ('ocultar');
                            excursion_lang[0].classList.add ('required');
                        }

                        create_array_for_product ('category_id', product_type[0]);
                        create_array_for_product ('category_code', product_type[1]);
                        create_array_for_product ('dealer_code', product_type[3]);
                        create_array_for_product ('dealer_name', product_type[4]);
                        create_array_for_product ('dealer_id', product_type[5]);

                        get_contract ().then (
                            contract => {
                                // console.log(contract);
                            }
                        )

                        excursion_date.prop ('disabled', false);

                        //BUSCAR MODALIDADES DADO UN DEALER Y UN PRODUCTO
                        let modality_selected = '';
                        excursion_modality.prop ('disabled', false).select2 ({
                            theme: "bootstrap",
                            ajax: {
                                url: `${location.origin}/${sessionStorage.getItem ('locale')}/modalities`,
                                dataType: "json",
                                language: "es",
                                data: function (params) {
                                    return {
                                        search: params.term,
                                        page: params.page || 1,
                                        product: product_type[0],
                                        dealer: product_type[5]
                                    }

                                },
                                type: 'GET',
                                processResults: function (data, params) {
                                    let result = [];
                                    params.page = params.page || 1;
                                    data.rows.map ((elem, key) => {
                                        result.push ({
                                            id: elem,
                                            text: elem
                                        })
                                    })

                                    if (data.total > 10) {

                                        if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": true
                                                }
                                            }
                                        } else {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": false
                                                }
                                            }
                                        }

                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }
                                }
                            }
                        }).on ('change', function (e) {
                            modality_selected = e.target.value;

                            create_array_for_product ('modalities_name', e.target.value);


                            //OCULTANDO Y MOSTRANDO INPUTS EN DEPENDENCIA DE LO QUE NOS DEVUELVA EL SERVICIO PARA PRODUCTOS TIPO BICICLETAS
                            if (product_name.includes ('bicicleta')) {
                                excursion_pax_block[0].classList.add ('ocultar');
                                excursion_adult_childrens[0].classList.add ('ocultar');
                                excursion_cantidad_block[0].classList.remove ('ocultar');

                                excursion_cantidad[0].setAttribute ('data-min', product_type[6]);
                                excursion_cantidad[0].setAttribute ('data-max', product_type[7]);
                                create_array_for_product ('day_min_rent', product_type[6]);
                                create_array_for_product ('day_max_rent', product_type[7]);
                                excursion_cantidad.val (1);
                                excursion_cantidad.trigger ('change');

                            } else {
                                modality_info (product_type[5], product_type[0], modality_selected).then (
                                    modality => {
                                        if (modality.info === 'true') {
                                            if (modality.has_pax) {
                                                //OCULTANDO Y MOSTRANDO INPUTS EN DEPENDENCIA DE LO QUE NOS DEVUELVA EL SERVICIO
                                                excursion_pax_block[0].classList.add ('ocultar');
                                                excursion_cantidad_block[0].classList.add ('ocultar');
                                                excursion_adult_childrens[0].classList.remove ('ocultar');

                                                //SETEANDO ATRIBUTOS A LOS INPUTS PARA EVALUAR
                                                excursion_adultos[0].setAttribute ('data-min', modality.min);
                                                let min = 1;
                                                if ((parseInt (modality.min) - 1) <= 0) {
                                                    min = 0
                                                }
                                                excursion_childrens[0].setAttribute ('data-min', min)
                                                excursion_adultos[0].setAttribute ('data-max', modality.max);
                                                excursion_childrens[0].setAttribute ('data-max', (parseInt (modality.max) - 1))
                                                excursion_adultos[0].setAttribute ('data-evaluate-max', modality.max);
                                                excursion_childrens[0].setAttribute ('data-evaluate-max', modality.max)

                                                create_array_for_product ('day_min_rent', min);
                                                create_array_for_product ('day_max_rent', modality.max);
                                            } else {
                                                excursion_pax_block[0].classList.remove ('ocultar');
                                                excursion_cantidad_block[0].classList.add ('ocultar');
                                                excursion_adult_childrens[0].classList.add ('ocultar');
                                                excursion_pax.prop ('disabled', false)
                                            }
                                        }
                                    }
                                )
                            }


                        })

                        //BUSCAR MODALIDAD POR INPUT DE ADULTOS Y NIÑOS
                        excursion_adultos.on ('change', function (e) {
                            modality_info (product_type[5], product_type[0], modality_selected, false, e.target.value, excursion_childrens.val ()).then (
                                modality => {
                                    let dataset = e.target.dataset;
                                    let helper = $ (`#${dataset.helper}`);
                                    if (modality.data === null) {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = `<span class='text-danger'>Esta experiencia solo admite reservas para ${dataset.evaluateMax} PAX</span>`
                                        }
                                    } else {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = ``;
                                        }
                                        create_array_for_product ('modality_code', modality.data.code)
                                        create_array_for_product ('pax', dataset.evaluateMax);
                                        create_id_for_product (product_type[0], product_type[3], product_type[1], modality.data.code, dataset.evaluateMax).then ()

                                    }
                                }
                            );
                        })

                        excursion_childrens.on ('change', function (e) {
                            modality_info (product_type[5], product_type[0], modality_selected, false, excursion_adultos.val (), e.target.value).then (
                                modality => {
                                    let dataset = e.target.dataset;
                                    let helper = $ (`#${dataset.helper}`);
                                    if (modality.data === null) {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = `<span class='text-danger'>Esta experiencia solo admite reservas para ${dataset.evaluateMax} PAX</span>`
                                        }
                                    } else {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = ``;
                                        }
                                        create_array_for_product ('modality_code', modality.data.code)
                                        create_array_for_product ('pax', dataset.evaluateMax);
                                        create_id_for_product (product_type[0], product_type[3], product_type[1], modality.data.code, dataset.evaluateMax).then ()
                                    }
                                }
                            );
                        })

                        //SETEAR DATOS POR MODALIDAD
                        excursion_cantidad.on ('change', function (e) {
                            modality_info (product_type[5], product_type[0], modality_selected).then (
                                modality_by_cant => {
                                    create_array_for_product ('modality_code', modality_by_cant.data[0].code)
                                    create_array_for_product ('pax', e.target.value);
                                    create_array_for_product ('amount', e.target.value);

                                    create_id_for_product (product_type[0], product_type[3], product_type[1], modality_by_cant.data[0].code, e.target.value).then ()
                                }
                            )
                        })

                        //BUSCAR PAX POR MODALIDADES
                        excursion_pax.select2 ({
                            theme: "bootstrap",
                            ajax: {
                                url: '/modalities/pax',
                                dataType: "json",
                                language: "es",
                                data: function (params) {
                                    return {
                                        search: params.term,
                                        page: params.page || 1,
                                        product: product_type[0],
                                        dealer: product_type[5],
                                        modality: modality_selected
                                    }

                                },
                                type: 'GET',
                                processResults: function (data, params) {
                                    let result = [];
                                    params.page = params.page || 1;

                                    data.rows.map ((elem, key) => {
                                        result.push ({
                                            id: elem.id + "|" + elem.code + "|" + elem.max_reservation_amount,
                                            text: elem.name
                                        })
                                    })

                                    if (data.total > 10) {

                                        if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": true
                                                }
                                            }
                                        } else {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": false
                                                }
                                            }
                                        }

                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }
                                }
                            }
                        }).on ('change', function (e) {
                            let modality_type = e.target.value.split ("|");
                            create_array_for_product ('modality_code', modality_type[1])
                            create_array_for_product ('pax', modality_type[2]);
                            create_id_for_product (product_type[0], product_type[3], product_type[1], modality_type[1], modality_type[2]).then ()
                        })

                        flatpickr (excursion_date, {
                            altInput: true,
                            altFormat: "d-m-Y",
                            dateFormat: "Y-m-d",
                            disable: date_disabled,
                            minDate: new Date ().fp_incr (parseInt (product_type[2])),
                            maxDate: new Date ().fp_incr (90),
                            locale: flatpick_lang
                        });


                        excursion_date.on ('change', function (e) {
                            // SETEANDO FECHA EN QUE EMPIEZA Y TERMINA LA EXCURSION
                            setSessionVar ('from_date', e.target.value, 'add', true, false).then (
                                from => {
                                    create_array_for_product ('from_date', from.value);
                                }
                            );
                            setSessionVar ('to_date', e.target.value, 'add', true, false).then (
                                to => {
                                    create_array_for_product ('to_date', to.value);
                                }
                            );
                        })

                    })
                })


                //PARA AÑADIR DESDE EL DETAILS VIEW
                let excursion_to_details, excursion_modality_details, excursion_pax_details, excursion_date_details,
                    product_details, product_dataset, form_type_send_details, excursion_pax_block_details,
                    excursion_adult_childrens_details, excursion_adultos_details, excursion_childrens_details,
                    excursion_cantidad_block_details, excursion_cantidad_details,
                    event_to_details, event_modality_details, event_cantidad_details;
                excursion_to_details = $ ('#excursion_to_details');
                event_to_details = $ ('#event_to_details');
                excursion_modality_details = $ ('#excursion_modality_details');
                event_modality_details = $ ('#event_modality_details').prop ('disabled', true).select2 ({theme: 'bootstrap'});
                excursion_pax_block_details = $ ('#excursion_pax_block_details');
                excursion_adult_childrens_details = $ ('#excursion_adult_childrens_details');
                excursion_adultos_details = $ ('#excursion_adultos_details');
                excursion_childrens_details = $ ('#excursion_childrens_details');
                excursion_cantidad_block_details = $ ('#excursion_cantidad_block_details');
                excursion_cantidad_details = $ ('#excursion_cantidad_details');
                event_cantidad_details = $ ('#event_cantidad_details').prop ('disabled', true);
                excursion_pax_details = $ ('#excursion_pax_details');
                excursion_date_details = $ ('#excursion_date_details');
                excursion_date_details.prop ('disabled', true);
                excursion_modality_details.prop ('disabled', true).select2 ({theme: 'bootstrap'});
                excursion_pax_details.prop ('disabled', true).select2 ({theme: 'bootstrap'});
                product_details = $ ('#product_details');
                let modality_details = $ ('#modality_details');
                let product_all_product = '';

                // DATOIS DEL PRODUCTO QUE SE ESTA VISUALIZANDO
                if (product_details !== undefined && product_details.length > 0) {
                    product_dataset = product_details[0].dataset;
                    all_product = product_dataset.allProduct;
                    dealer_id = product_dataset.dealerId;
                    dealer_code = product_dataset.dealerCode;
                    product_id = product_dataset.id;
                }


                excursion_to_details.select2 ({
                    theme: "bootstrap",
                    ajax: {
                        url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination`,
                        dataType: "json",
                        language: "es",
                        data: function (params) {
                            return {
                                form_type: product_dataset.type,
                                search: params.term,
                                page: params.page || 1,
                                product_id: product_dataset.id,
                                all_product: all_product
                            }

                        },
                        type: 'GET',
                        processResults: function (data, params) {
                            let result = [];
                            params.page = params.page || 1;

                            data.rows.map ((elem, key) => {
                                result.push ({
                                    id: elem.code + '|' + elem.id + '|' + elem.name,
                                    text: elem.name
                                })
                            })

                            if (data.total > 10) {

                                if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": true
                                        }
                                    }
                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }

                            } else {
                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }
                }).on ('change', function (e) {
                    let data_excursion_to_details = e.target.value.split ("|");

                    create_array_for_product ('product_type', product_dataset.type);
                    create_array_for_product ('destination_id', data_excursion_to_details[1]);
                    create_array_for_product ('destination_code', data_excursion_to_details[0]);
                    create_array_for_product ('text_destination', data_excursion_to_details[2]);
                    if (all_product !== "true") {
                        create_array_for_product ('category_id', product_dataset.id);
                        create_array_for_product ('category_code', product_dataset.code);
                        create_array_for_product ('dealer_code', product_dataset.dealerCode);
                        create_array_for_product ('dealer_name', product_dataset.dealerName);
                        create_array_for_product ('dealer_id', product_dataset.dealerId);
                    }

                    //BUSCAR MODALIDADES DADO UN DEALER Y UN PRODUCTO
                    let modality_selected_details = '';
                    let dealers_array = '';
                    excursion_modality_details.prop ('disabled', false).select2 ({
                        theme: "bootstrap",
                        ajax: {
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/modalities`,
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1,
                                    product: product_dataset.id,
                                    dealer: product_dataset.dealerId,
                                    all_product: all_product,
                                    destination_id: data_excursion_to_details[1]
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;

                                dealers_array = data.dealers;

                                if (all_product === 'true') {
                                    product_all_product = data.product;
                                    create_array_for_product ('category_id', data.product.id);
                                    create_array_for_product ('category_code', data.product.code);
                                }

                                data.rows.map ((elem, key) => {
                                    result.push ({
                                        id: elem,
                                        text: elem
                                    })
                                })

                                if (data.total > 10) {

                                    if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": true
                                            }
                                        }
                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }

                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }
                            }
                        }
                    }).on ('change', function (e) {
                        modality_selected_details = e.target.value;
                        create_array_for_product ('modalities_name', e.target.value)


                        if (all_product === "true") {
                            create_array_for_product ('dealer_code', dealers_array[modality_selected_details].code);
                            create_array_for_product ('dealer_name', dealers_array[modality_selected_details].name);
                            create_array_for_product ('dealer_id', dealers_array[modality_selected_details].id);
                            dealer_id = dealers_array[modality_selected_details].id;
                            dealer_code = dealers_array[modality_selected_details].code;
                            product_id = product_dataset.code;
                        }


                        //ACTIVAR EL CAMPO DE FECHA
                        excursion_date_details.removeAttr ('disabled');
                        flatpickr (excursion_date_details, {
                            altInput: true,
                            altFormat: "d-m-Y",
                            dateFormat: "Y-m-d",
                            disable: date_disabled,
                            minDate: new Date ().fp_incr (parseInt (product_dataset.reservation)),
                            maxDate: new Date ().fp_incr (90),
                            locale: flatpick_lang
                        });

                        modality_info (dealer_id, product_id, modality_selected_details).then (
                            modality => {

                                if (modality_details !== undefined && modality_details.length > 0) {
                                    if (!modality.description.isEmpty ()) {
                                        modality_details.html (`<hr><h5>${I18n.t ('main.body.catalog.modality_description')}</h5>${modality.description}`);
                                    }
                                }

                                if (modality.info === 'true') {
                                    if (modality.has_pax) {
                                        //OCULTANDO Y MOSTRANDO INPUTS EN DEPENDENCIA DE LO QUE NOS DEVUELVA EL SERVICIO
                                        excursion_pax_block_details[0].classList.add ('ocultar');
                                        excursion_adult_childrens_details[0].classList.remove ('ocultar');
                                        excursion_cantidad_block_details[0].classList.add ('ocultar');

                                        //SETEANDO ATRIBUTOS A LOS INPUTS PARA EVALUAR
                                        excursion_adultos_details[0].setAttribute ('data-min', modality.min);
                                        let min = 1;
                                        if ((parseInt (modality.min) - 1) <= 0) {
                                            min = 0
                                        }
                                        excursion_childrens_details[0].setAttribute ('data-min', min)
                                        excursion_adultos_details[0].setAttribute ('data-max', modality.max);
                                        create_array_for_product ('day_min_rent', min);
                                        create_array_for_product ('day_max_rent', modality.max);
                                        excursion_childrens_details[0].setAttribute ('data-max', (parseInt (modality.max) - 1))
                                        excursion_adultos_details[0].setAttribute ('data-evaluate-max', modality.max);
                                        excursion_childrens_details[0].setAttribute ('data-evaluate-max', modality.max)
                                    } else {
                                        if (product_dataset.name.toLowerCase ().includes ('bicicleta')) {
                                            excursion_pax_block_details[0].classList.add ('ocultar');
                                            excursion_adult_childrens_details[0].classList.add ('ocultar');
                                            excursion_cantidad_block_details[0].classList.remove ('ocultar');

                                            excursion_cantidad_details[0].setAttribute ('data-min', product_dataset.minAmount);
                                            excursion_cantidad_details[0].setAttribute ('data-max', product_dataset.maxAmount);
                                            create_array_for_product ('day_min_rent', product_dataset.minAmount);
                                            create_array_for_product ('day_max_rent', product_dataset.maxAmount);
                                            excursion_cantidad_details.val (product_dataset.minAmount);

                                        } else {
                                            excursion_pax_block_details[0].classList.remove ('ocultar');
                                            excursion_cantidad_block_details[0].classList.add ('ocultar');
                                            excursion_adult_childrens_details[0].classList.add ('ocultar');
                                            excursion_pax_details.prop ('disabled', false)
                                        }

                                    }
                                }
                            }
                        )

                        //BUSCAR MODALIDAD POR INPUT DE ADULTOS Y NIÑOS
                        excursion_adultos_details.on ('change', function (e) {
                            modality_info (dealer_id, product_id, modality_selected_details, false, e.target.value, excursion_childrens_details.val ()).then (
                                modality => {
                                    let dataset = e.target.dataset;
                                    let helper = $ (`#${dataset.helper}`);
                                    if (modality.data === null) {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = `<span class='text-danger bg-white'>Esta experiencia solo admite reservas para ${dataset.evaluateMax} PAX</span>`
                                        }
                                    } else {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = ``;
                                        }
                                        create_array_for_product ('modality_code', modality.data.code)
                                        create_array_for_product ('pax', dataset.evaluateMax);

                                        if (all_product === 'true') {
                                            create_id_for_product (product_all_product.id, dealer_code, product_all_product.code, modality.data.code, dataset.evaluateMax).then ()
                                        } else {
                                            create_id_for_product (product_dataset.id, product_dataset.dealerCode, product_dataset.code, modality.data.code, dataset.evaluateMax).then ()
                                        }

                                    }
                                }
                            );
                        })

                        excursion_childrens_details.on ('change', function (e) {
                            modality_info (dealer_id, product_id, modality_selected_details, false, excursion_adultos_details.val (), e.target.value).then (
                                modality => {
                                    let dataset = e.target.dataset;
                                    let helper = $ (`#${dataset.helper}`);
                                    if (modality.data === null) {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = `<span class='text-danger bg-white'>Esta experiencia solo admite reservas para ${dataset.evaluateMax} PAX</span>`
                                        }
                                    } else {
                                        if (helper.length > 0) {
                                            helper[0].innerHTML = ``;
                                        }

                                        create_array_for_product ('modality_code', modality.data.code)
                                        create_array_for_product ('pax', dataset.evaluateMax);
                                        if (all_product === 'true') {
                                            create_id_for_product (product_all_product.id, dealer_code, product_all_product.code, modality.data.code, dataset.evaluateMax).then ()
                                        } else {
                                            create_id_for_product (product_dataset.id, product_dataset.dealerCode, product_dataset.code, modality.data.code, dataset.evaluateMax).then ()
                                        }
                                    }
                                }
                            );
                        })

                        //SETEAR MODALIDAD POR CANTIDAD
                        excursion_cantidad_details.on ('change', function (e) {
                            modality_info (dealer_id, product_id, modality_selected_details).then (
                                modality_by_cant => {
                                    create_array_for_product ('modality_code', modality_by_cant.data[0].code)
                                    create_array_for_product ('pax', e.target.value);
                                    create_array_for_product ('amount', e.target.value);
                                    if (all_product === 'true') {
                                        create_id_for_product (product_all_product.id, dealer_code, product_all_product.code, modality_by_cant.data[0].code, e.target.value).then ()
                                    } else {
                                        create_id_for_product (product_dataset.id, product_dataset.dealerCode, product_dataset.code, modality_by_cant.data[0].code, e.target.value).then ()
                                    }

                                }
                            )
                        })

                        //BUSCAR PAX POR MODALIDAD EN DETAILS VIEW
                        excursion_pax_details.select2 ({
                            theme: "bootstrap",
                            ajax: {
                                url: '/modalities/pax',
                                dataType: "json",
                                language: "es",
                                data: function (params) {
                                    return {
                                        search: params.term,
                                        page: params.page || 1,
                                        product: product_dataset.id,
                                        dealer: product_dataset.dealerId,
                                        modality: modality_selected_details
                                    }

                                },
                                type: 'GET',
                                processResults: function (data, params) {
                                    let result = [];
                                    params.page = params.page || 1;

                                    data.rows.map ((elem, key) => {
                                        result.push ({
                                            id: elem.id + "|" + elem.code + "|" + elem.max_reservation_amount,
                                            text: elem.name
                                        })
                                    })

                                    if (data.total > 10) {

                                        if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": true
                                                }
                                            }
                                        } else {
                                            return {
                                                results: result,
                                                "pagination": {
                                                    "more": false
                                                }
                                            }
                                        }

                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }
                                }
                            }
                        }).on ('change', function (e) {
                            let modality_type = e.target.value.split ("|");

                            create_array_for_product ('modality_code', modality_type[1])
                            create_array_for_product ('pax', modality_type[2]);
                            if (all_product === 'true') {
                                create_id_for_product (product_all_product.id, dealer_code, product_all_product.code, modality_type[1], modality_type[2]).then ()
                            } else {
                                create_id_for_product (product_dataset.id, product_dataset.dealerCode, product_dataset.code, modality_type[1], modality_type[2]).then ()
                            }


                        })


                        excursion_date_details.on ('change', function (e) {
                            // SETEANDO FECHA EN QUE EMPIEZA Y TERMINA LA EXCURSION
                            setSessionVar ('from_date', e.target.value, 'add', true, false).then (
                                from => {
                                    create_array_for_product ('from_date', from.value);
                                }
                            );
                            setSessionVar ('to_date', e.target.value, 'add', true, false).then (
                                to => {
                                    create_array_for_product ('to_date', to.value);
                                }
                            );
                        });
                    })
                })

                event_to_details.select2 ({
                    theme: "bootstrap",
                    ajax: {
                        url: '/destination',
                        dataType: "json",
                        language: "es",
                        data: function (params) {
                            return {
                                form_type: product_dataset.type,
                                search: params.term,
                                page: params.page || 1,
                                product_id: product_dataset.id,
                                all_product: all_product
                            }

                        },
                        type: 'GET',
                        processResults: function (data, params) {
                            let result = [];
                            params.page = params.page || 1;

                            event_product = data.type[0].product;
                            event_dealer = data.type[0].dealer;

                            data.rows.map ((elem, key) => {
                                result.push ({
                                    id: elem.code + '|' + elem.id,
                                    text: elem.name
                                })
                            })

                            if (data.total > 10) {

                                if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": true
                                        }
                                    }
                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }

                            } else {
                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }
                }).on ('change', function (e) {
                    let data_event_to_details = e.target.value.split ("|");
                    create_array_for_product ('product_type', product_dataset.type);
                    create_array_for_product ('destination_id', data_event_to_details[1]);
                    create_array_for_product ('destination_code', data_event_to_details[0]);
                    create_array_for_product ('text_destination', e.target.selectedOptions[0].innerText);
                    create_array_for_product ('category_id', product_dataset.id);
                    create_array_for_product ('category_code', product_dataset.code);
                    create_array_for_product ('dealer_code', product_dataset.dealerCode);
                    create_array_for_product ('dealer_name', product_dataset.dealerName);
                    create_array_for_product ('dealer_id', product_dataset.dealerId);

                    setSessionVar ('from_date', event_product.date_event, 'add', true, false).then (
                        from => {
                            create_array_for_product ('from_date', from.value);
                        }
                    );
                    setSessionVar ('to_date', event_product.date_event, 'add', true, false).then (
                        to => {

                            create_array_for_product ('to_date', to.value);
                        }
                    );


                    //TIPO DE EVENTO
                    event_modality_details.select2 ({
                        theme: "bootstrap",
                        ajax: {
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_dealer`,
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1,
                                    destination: data_event_to_details[1],
                                    dealer: product_dataset.dealerCode,
                                    form_type: product_dataset.type
                                }

                            },
                            dataType: "json",
                            language: "es",
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;

                                data.services.map ((elem, key) => {

                                    result.push ({
                                        id: elem.id + "|" + elem.code + "|" + elem.reservation_time + "|" + data.dealer_code + "|" + data.dealer_name + "|" + data.dealer_id + "|" + elem.min_reservation_amount + "|" + elem.max_reservation_amount,
                                        text: elem.name
                                    })
                                })


                                if (data.total > 10) {

                                    if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": true
                                            }
                                        }
                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }

                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }
                            }
                        }
                    }).prop ('disabled', false).on ('change', function (e) {
                        event_cantidad_details.prop ('disabled', false);
                        let product_type = e.target.value.split ("|");
                        create_array_for_product ('day_min_rent', product_type[6]);
                        create_array_for_product ('day_max_rent', product_type[7]);

                        create_array_for_product ('category_product_name', e.target.selectedOptions[0].innerText);
                        $.ajax ({
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/modalities_by_dealer`,
                            data: {
                                dealer: product_dataset.dealerCode,
                                product: product_dataset.code
                            },
                            type: 'GET',
                            success: function (modalities_data_details) {
                                if (modalities_data_details !== null) {
                                    create_array_for_product ('modalities_name', modalities_data_details.name)
                                    create_array_for_product ('modality_code', modalities_data_details.code)
                                    create_id_for_product (product_dataset.id, product_dataset.dealerCode, product_dataset.code, modalities_data_details.code, 0).then ()
                                }
                            }
                        })

                    })
                })
                //FIN DE EXCURSIONES Y EVENTOS DETAILS

                //NUEVO PRODUCTO EVENTOS

                let event_to, event_product = '', event_dealer = '', event_destination = '', event_type, event_cuantity;
                event_to = $ ('#event_to');
                event_cuantity = $ ('#event_cuantity').prop ('disabled', true);
                event_type = $ ('#event_type').select2 ({theme: "bootstrap"}).prop ('disabled', true);
                event_to.select2 ({
                    theme: "bootstrap",
                    ajax: {
                        url: '/destination',
                        dataType: "json",
                        language: "es",
                        data: function (params) {
                            return {
                                form_type: form_type_send.val (),
                                search: params.term,
                                page: params.page || 1
                            }

                        },
                        type: 'GET',
                        processResults: function (data, params) {
                            let result = [];
                            params.page = params.page || 1;

                            event_product = data.type[0].product;
                            event_dealer = data.type[0].dealer;
                            event_destination = data.type[0].destination

                            data.rows.map ((elem, key) => {
                                result.push ({
                                    id: elem.code + '|' + elem.id,
                                    text: elem.name
                                })
                            })

                            if (data.total > 10) {

                                if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": true
                                        }
                                    }
                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }

                            } else {
                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }
                }).on ('change', function (e) {
                    let data_event_to = e.target.value.split ("|");
                    create_array_for_product ('product_type', form_type_send.val ());
                    create_array_for_product ('destination_id', data_event_to[1]);
                    create_array_for_product ('destination_code', data_event_to[0]);
                    create_array_for_product ('text_destination', event_destination.name);
                    create_array_for_product ('category_id', event_product.id);
                    create_array_for_product ('category_code', event_product.code);
                    create_array_for_product ('dealer_code', event_dealer.code);
                    create_array_for_product ('dealer_name', event_dealer.name);
                    create_array_for_product ('dealer_id', event_dealer.id);
                    setSessionVar ('from_date', event_product.date_event, 'add', true, false).then (
                        from => {
                            create_array_for_product ('from_date', from.value);
                        }
                    );
                    setSessionVar ('to_date', event_product.date_event, 'add', true, false).then (
                        to => {

                            create_array_for_product ('to_date', to.value);
                        }
                    );


                    //TIPO DE EVENTO
                    event_type.select2 ({
                        theme: "bootstrap",
                        ajax: {
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_dealer`,
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1,
                                    destination: data_event_to[1],
                                    dealer: event_dealer.code,
                                    form_type: form_type_send.val ()
                                }

                            },
                            dataType: "json",
                            language: "es",
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;

                                data.services.map ((elem, key) => {

                                    result.push ({
                                        id: elem.id + "|" + elem.code + "|" + elem.reservation_time + "|" + data.dealer_code + "|" + data.dealer_name + "|" + data.dealer_id + "|" + elem.min_reservation_amount + "|" + elem.max_reservation_amount,
                                        text: elem.name
                                    })
                                })


                                if (data.total > 10) {

                                    if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": true
                                            }
                                        }
                                    } else {
                                        return {
                                            results: result,
                                            "pagination": {
                                                "more": false
                                            }
                                        }
                                    }

                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }
                            }
                        }
                    }).prop ('disabled', false).on ('change', function (e) {
                        let product_type = e.target.value.split ("|");

                        event_cuantity.prop ('disabled', false);
                        event_cuantity.attr ('value', product_type[6]);
                        event_cuantity.attr ('data-min', product_type[6]);
                        event_cuantity.attr ('data-max', product_type[7]);
                        create_array_for_product ('day_min_rent', product_type[6]);
                        create_array_for_product ('day_max_rent', product_type[7]);

                        create_array_for_product ('category_id', product_type[0]);
                        create_array_for_product ('category_code', product_type[1]);
                        create_array_for_product ('category_product_name', e.target.selectedOptions[0].innerText);

                        $.ajax ({
                            url: `${location.origin}/${sessionStorage.getItem ('locale')}/modalities_by_dealer`,
                            data: {
                                dealer: event_dealer.code,
                                product: product_type[1]
                            },
                            type: 'GET',
                            success: function (modalities_data) {
                                if (modalities_data !== null) {
                                    create_array_for_product ('modalities_name', modalities_data.name)
                                    create_array_for_product ('modality_code', modalities_data.code)
                                    create_id_for_product (product_type[0], event_dealer.code, product_type[1], modalities_data.code, 0).then ()
                                }
                            }
                        })

                    })
                })

                let event_products, event_modality, event_category_person, event_entity, event_amount;
                let dealer_service_array = [];
                event_products = $ ('#event_product');
                event_modality = $ ('#event_modality').select2 ({
                    theme: "bootstrap",
                    placeholder: "Seleccione un elemento"
                }).prop ('disabled', true);
                event_category_person = $ ('#event_category_person').select2 ({
                    theme: "bootstrap",
                    placeholder: "Seleccione un elemento"
                }).prop ('disabled', true);
                event_entity = $ ('#event_entity').prop ('disabled', true);
                event_amount = $ ('#event_amount').prop ('disabled', true);

                event_products.select2 ({
                    theme: "bootstrap",
                    placeholder: "Seleccione un elemento",
                    ajax: {
                        url: location.origin + '/services',
                        dataType: "json",
                        language: "es",
                        data: function (params) {
                            return {
                                form_type: form_type_send.val (),
                                search: params.term,
                                page: params.page || 1
                            }

                        },
                        type: 'GET',
                        processResults: function (data, params) {
                            let result = [];
                            params.page = params.page || 1;

                            dealer_service_data = data;

                            data.map ((dealer, key) => {
                                let aux = {
                                    "text": dealer.dealer_name,
                                    "children": []
                                }

                                if (dealer.services.length > 0) {
                                    dealer.services.map ((service, index) => {
                                        aux.children.push ({
                                            "id": `dealer_index:${key}|service_index:${index}`,
                                            "text": service.name
                                        })
                                    })
                                }
                                result.push (aux);

                            })

                            return {
                                results: result,
                                "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }).on ('change', function (e) {
                    //LIMPIAR CAMPOS
                    event_modality.empty ();
                    event_modality.removeAttr ('disabled');
                    event_category_person.removeAttr ('disabled');
                    event_entity.removeAttr ('disabled');
                    event_amount.removeAttr ('disabled');

                    //BUSCRA ELEMENTOS DEL DEALER Y EL SERVICIO SELECCIONADO
                    let element = e.target.value;
                    element = element.split ("|");

                    let dealer_index = element[0].split ('dealer_index:')[1];
                    let service_index = element[1].split ('service_index:')[1];

                    let dealer = dealer_service_data[dealer_index];
                    let product = dealer_service_data[dealer_index].services[service_index];

                    //SETEAR VALORES DE CANTIDAD DE PERSONAS
                    event_amount[0].value = product.min_reservation_amount;
                    event_amount[0].setAttribute('data-action','change->preferences#evaluate');
                    event_amount[0].setAttribute('data-min',product.min_reservation_amount);
                    event_amount[0].setAttribute('data-max',product.max_reservation_amount);
                    event_amount[0].setAttribute('data-block','event_btn');

                    //SETEAR VALORES PARA ENVIAR POSTERIORMENTE AL CARRITO
                    create_array_for_product ('product_type', form_type_send.val ());
                    create_array_for_product ('amount', product.min_reservation_amount);
                    create_array_for_product ('pax', product.min_reservation_amount);
                    create_array_for_product ('has_accreditation', product.has_accreditation);
                    // create_array_for_product ('destination_code', data_event_to[0]);
                    // create_array_for_product ('text_destination', event_destination.name);
                    create_array_for_product ('category_id', product.id);
                    create_array_for_product ('category_code', product.code);
                    create_array_for_product ('category_product_name', product.name);
                    create_array_for_product ('dealer_code', dealer.dealer_code);
                    create_array_for_product ('dealer_name', dealer.dealer_name);
                    create_array_for_product ('dealer_id', dealer.dealer_id);
                    create_array_for_product ('min_reservation_amount', product.min_reservation_amount);
                    create_array_for_product ('max_reservation_amount', product.max_reservation_amount);

                    setSessionVar ('from_date', product.date_event, 'add', true, false).then (
                        from => {
                            create_array_for_product ('from_date', from.value);
                        }
                    );
                    setSessionVar ('to_date', product.to_date, 'add', true, false).then (
                        to => {

                            create_array_for_product ('to_date', to.value);
                        }
                    );

                    event_modality.select2 ({
                        theme: "bootstrap",
                        placeholder: "Seleccione un elemento",
                        ajax: {
                            url: location.origin + '/modalities_by_dealer',
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    form_type: form_type_send.val (),
                                    search: params.term,
                                    page: params.page || 1,
                                    dealer: dealer.dealer_code,
                                    product: product.code
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];

                                params.page = params.page || 1;
                                modalities_data = data;
                                data.map ((modality, key_modality) => {
                                    result.push ({
                                        "id": key_modality,
                                        "text": modality.name
                                    })
                                })

                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }).on('change', function (e) {
                        let modality = modalities_data[e.target.value];
                        create_array_for_product ('modalities_name', modality.name)
                        create_array_for_product ('modality_code', modality.code)
                        create_id_for_product (product.id, dealer.dealer_code, product.code, modality.code, 0).then ();
                    })

                    event_category_person.select2 ({
                        theme: "bootstrap",
                        placeholder: "Seleccione un elemento",
                        ajax: {
                            url: location.origin + '/admin/person_categories/get_json',
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];

                                params.page = params.page || 1;
                                person_category_data = data;
                                data.map ((categry_person, key_categry_person) => {
                                    result.push ({
                                        "id": key_categry_person,
                                        "text": categry_person.name
                                    })
                                })

                                return {
                                    results: result,
                                    "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        }
                    }).on('change', function (e) {
                        category_value = e.target.selectedOptions[0].text;
                        event_make_comment();
                        if(person_category_data[e.target.value].request_entity){
                            $(event_entity[0].parentNode)[0].classList.remove('ocultar');
                        }else{
                            $(event_entity[0].parentNode)[0].classList.add('ocultar');
                            event_entity[0].value = '';
                        }
                    });

                    event_amount.on('change', e => {
                        create_array_for_product ('amount', e.target.value);
                        create_array_for_product ('pax', e.target.value);
                    })

                    event_entity.on('keyup', function (e) {
                        entity_value = e.target.value;
                        event_make_comment();
                    })
                })
                //FIN DE EVENTOS


                $.ajax (
                    {
                        url: `/${locale.locale}/preferences/to/general.json`,
                        data: {},
                        type: "get",
                        success: function (data) {
                            preferences = data;
                            data.map ((elem, key) => {
                                if (data.hasOwnProperty (key)) {
                                    let target = modifiedKey (data[key].key.text);
                                    let show = data[key].show;
                                    switch (target) {
                                        case 'siteName':
                                            if (show) {
                                                if (context.hasSiteNameTarget) {
                                                    if (data[key].has_image) {
                                                        if (location.pathname.includes ("/admin") || location.pathname.includes ("/initial/config")) {
                                                            context.siteNameTarget.innerHTML = `Administración <span>${data[key].value}</span>`;

                                                        } else {
                                                            if (location.pathname.includes ("/commercial")) {
                                                                context.siteNameTarget.innerHTML = `Comercial <span>${data[key].value}</span>`;
                                                            } else {
                                                                context.siteNameTarget.innerHTML = `<img  src="${data[key].background_image}" alt="${data[key].value}">`;
                                                            }

                                                        }
                                                    } else {
                                                        context.siteNameTarget.innerHTML = `<span>${data[key].value}</span>`;
                                                    }

                                                }

                                                if (context.hasTicketLogoTarget) {
                                                    if (data[key].has_image) {
                                                        context.ticketLogoTarget.innerHTML = `<img src="${data[key].background_image}" alt="${data[key].value}" >`;
                                                    } else {
                                                        context.ticketLogoTarget.innerHTML = `<span>${data[key].value}</span>`;
                                                    }

                                                }

                                            } else {
                                                context.siteNameTarget.parentNode.remove ();
                                            }

                                            break;
                                        case 'collectPlace':
                                            if (context.hasCollectPlaceTarget) {
                                                context.collectPlaceTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'textDestination':
                                            if (context.hasTextDestinationTarget) {
                                                context.textDestinationTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'dateCollect':
                                            if (context.hasDateCollectTarget) {
                                                context.dateCollectTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'dateDelivery':
                                            if (context.hasDateDeliveryTarget) {
                                                context.dateDeliveryTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'timeCollect':
                                            if (context.hasTimeCollectTarget) {
                                                context.timeCollectTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'timeDelivery':
                                            if (context.hasTimeDeliveryTarget) {
                                                context.timeDeliveryTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'textButton':
                                            if (context.hasTextButtonTarget) {
                                                context.textButtonTargets.map ((elem) => {
                                                    elem.innerText = data[key].value;
                                                })
                                            }
                                            break;
                                        case 'textsearchMark':
                                            if (context.hasTextSearchMarkTarget) {
                                                context.textSearchMarkTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'textsearchModel':
                                            if (context.hasTextSearchModelTarget) {
                                                context.textSearchModelTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'Category':
                                            if (context.hasCategoryTarget) {
                                                context.categoryTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'Modality':
                                            if (context.hasModalityTarget) {
                                                context.modalityTarget.innerHTML = data[key].value;
                                            }
                                            break;
                                        case 'contactNumber':
                                            if (context.hasContactNumberTarget) {
                                                context.contactNumberTarget.innerHTML = `${data[key].value}`;
                                            }
                                            if (context.hasContactNumberTwoTarget) {
                                                context.contactNumberTwoTarget.innerHTML = `${data[key].value}`;
                                            }
                                            break;
                                        case 'siteNameFooter':
                                            if (data[key].has_image) {
                                                let footer = document.getElementById ('footer-image');
                                                if (footer !== null) {
                                                    footer.style.setProperty ("--picture", "url('" + data[key].background_image + "')")
                                                }

                                            }

                                            if (context.hasSiteNameFooterTarget) {
                                                if (data[key].has_image) {
                                                    context.siteNameFooterTarget.innerHTML = `<img src="${data[key].background_image}" alt="${data[key].value}">`;
                                                } else {
                                                    context.siteNameFooterTarget.innerHTML = `<span>${data[key].value}</span>`;
                                                }
                                            }

                                            break;
                                        case 'contactEmail':
                                            if (context.hasContactEmailTarget) {
                                                context.contactEmailTarget.innerHTML = `<a style="text-decoration: underline !important; " href="mailto:${data[key].value}">${data[key].value}</a>`;
                                            }
                                            if (context.hasContactEmailTwoTarget) {
                                                context.contactEmailTwoTarget.innerHTML = `<a style="text-decoration: underline !important; " href="mailto:${data[key].value}">${data[key].value}</a>`;
                                            }
                                            break;
                                        case 'contactAddress':
                                            if (context.hasContactAddressTarget) {
                                                context.contactAddressTarget.innerHTML = `${data[key].value}`;
                                            }
                                            break;
                                        case 'formText':
                                            if (context.hasFormTextTarget) {
                                                context.formTextTarget.innerHTML = `${data[key].value}`;
                                            }
                                            break;
                                        case 'formDescription':
                                            if (context.hasFormDescriptionTarget) {
                                                context.formDescriptionTarget.innerHTML = `${data[key].value}`;
                                            }
                                            break;
                                        case 'formdescriptionPlaceholder':
                                            if (context.hasFormDescriptionPlaceholderTarget) {
                                                context.formDescriptionPlaceholderTarget.setAttribute ("placeholder", data[key].value);
                                            }
                                            break;
                                    }

                                }
                            })
                        },
                        complete: function () {
                        }
                    }
                );


            })

    }

    set_form_type (e) {
        let data = e.target.dataset;
        if (data.hasOwnProperty ('formType')) {
            data = data.formType;
        } else {
            parent = e.target.parentNode;
            if (parent.dataset.hasOwnProperty ('formType')) {
                data = parent.dataset.formType
            } else {
                let element = e.target.viewportElement
                data = element.parentNode.dataset.formType
            }
        }

        $ ('#search_form_type').val (data);
        create_array_for_product ('', '', 'clear');
        create_array_for_product ('product_type', data);

        setSessionVar ('product_type', data, 'add', false, false).then (
            pt => {

            }
        )
    }

    make_comment(e){
        location_value = e.target.value;
        let text = '';
        if(!location_value.isEmpty() && !lang_value.isEmpty()){
            text = `Recogida en: ${e.target.value}, Idioma: ${lang_value}`;
        }else{
            if(!location_value.isEmpty()){
                text = `Recogida en: ${e.target.value}`;
            }
            if(!lang_value.isEmpty()){
                text = `Idioma: ${lang_value}`;
            }
        }

        create_array_for_product ('comment', text);
    }

    evaluate (e) {
        let element = e.target.dataset;
        let min = parseInt (element.min);
        let max = parseInt (element.max);
        let helper = $ (e.target.parentNode).find ('.helper-text')[0];
        let block = $ (`#${element.block}`);

        if (parseInt (e.target.value) < min) {
            e.target.classList.add ('is-invalid');
            if (helper !== undefined) {
                helper.innerHTML = `<span class='text-danger'>El valor del campo debe estar entre ${min} y ${max}</span>`;
            }
            block.prop ('disabled', true);
            e.target.value = parseInt (min);
        } else {
            if (parseInt (e.target.value) > max) {
                e.target.classList.add ('is-invalid');
                if (helper !== undefined) {
                    helper.innerHTML = `<span class='text-danger'>El valor del campo debe estar entre ${min} y ${max}</span>`;
                }
                block.prop ('disabled', true);
                e.target.value = parseInt (max);
            } else {
                e.target.classList.remove ('is-invalid');
                if (helper !== undefined) {
                    helper.innerHTML = '';

                    create_array_for_product ('pax', e.target.value)
                }
                block.prop ('disabled', false);
                create_array_for_product ('amount', e.target.value);

            }
        }


        if (element.hasOwnProperty ('compare') && element.compare === 'true') {
            let element_compare = $ (`#${element.evaluate}`);
            let helper_compare = $ (`#${element.helper}`);

            if (element_compare.length > 0) {
                if ((parseInt (e.target.value) + parseInt (element_compare.val ())) > element.evaluateMax) {
                    e.target.classList.add ('is-invalid');
                    element_compare.addClass ('is-invalid');

                    element_compare.val (parseInt (element.evaluateMax) - parseInt (e.target.value));

                    if (helper_compare.length > 0) {
                        helper_compare[0].innerHTML = "<span class='text-danger'>Solo permite hasta 3 personas</span>";
                    }
                } else {
                    e.target.classList.remove ('is-invalid');
                    element_compare.removeClass ('is-invalid');
                    if (helper_compare.length > 0) {
                        helper_compare[0].innerHTML = "";
                    }
                }
            }
        }
    }

    toogleClass (e) {
        let dataset = e.target.dataset;
        $ (`#${dataset.element}`).toggleClass (dataset.classToggle);
    }
}