import {Controller} from "stimulus"
import $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2'
import bootbox from "bootbox/bootbox";
import {get_popup} from './popup_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import {set_locale} from './localize_controller'
import {validate_email} from "./validate_controller";

export let verification = (not_authenticated = true) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/has_autenticated_or_invalid_email`,
        data: {},
        type: "GET",
        success: function (data) {
            if (data.authenticated) {
                if (!data.valid_email) {
                    if (data.valid_email_token) {
                        /*get_popup('verify_email_in_process').then(
                            verify_email_in_process => {
                                Swal.fire({
                                    title: verify_email_in_process.title || 'Cuenta en proceso de validación.',
                                    text: verify_email_in_process.description || "Por favor dirijase al correo electrónico que se le envió y haga click en el enlace de verificación para poder continuar. Si no se muestra en la bandeja de entrada, revisar en la carpeta de SPAM.",
                                    icon: verify_email_in_process.icon || 'info',
                                    showCancelButton: false,
                                    confirmButtonText: I18n.t('global.popup.verify_email_in_process_confirm'),
                                    cancelButtonText: '',
                                    reverseButtons: true,
                                    allowOutsideClick: false,
                                    // backdrop: `#354052`,
                                    backdrop: `rgba(255, 255, 255, 1)`,
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                        cancelButton: 'btn btn-secondary margin-right-15'
                                    },
                                    buttonsStyling: false
                                }).then((result) => {
                                    location.reload();
                                })
                            }
                        )*/
                    } else {
                        /*get_popup('verify_email').then(
                            verify_email => {
                                Swal.fire({
                                    title: verify_email.title || 'Cuenta no validada!!!',
                                    text: verify_email.description || "Su cuenta no esta validada, es necesario realizar acciones para validarla. Esto solo le tomara 1 min.",
                                    icon: verify_email.icon || 'warning',
                                    showCancelButton: false,
                                    confirmButtonText: I18n.t('global.popup.verify_email_confirm'),
                                    cancelButtonText: '',
                                    reverseButtons: true,
                                    allowOutsideClick: false,
                                    // backdrop: `#354052`,
                                    backdrop: `rgba(255, 255, 255, 1)`,
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                        cancelButton: 'btn btn-secondary margin-right-15'
                                    },
                                    buttonsStyling: false
                                }).then((result) => {
                                    location.href = location.origin + `/${sessionStorage.getItem('locale')}/validate`
                                })
                            }
                        )*/
                    }
                } else {
                    resolve({
                        validate: true
                    })
                }
            } else {
                if (not_authenticated) {
                    //verify_login_for_rent_cancel
                    /*get_popup('verify_login_for_rent').then(
                        popup => {
                                Swal.fire({
                                    title: popup.title || "Registrarse o Autenticarse.",
                                    text: popup.description || "Para poder continuar por favor Registrese en el sitio ó Autentiquese si ya posee credenciales. Esto solo le tomara 1 min.",
                                    icon: popup.icon || "info",
                                    showCancelButton: true,
                                    showDenyButton: true,
                                    confirmButtonText: I18n.t('global.popup.verify_login_for_rent_confirm'),
                                    cancelButtonText: I18n.t('global.popup.verify_login_for_rent_cancel'),
                                    denyButtonText: I18n.t('global.popup.verify_login_for_rent_denied'),
                                    reverseButtons: false,
                                    allowOutsideClick: false,
                                    // backdrop: `#354052`,
                                    backdrop: `rgba(255, 255, 255, 1)`,
                                    customClass: {
                                        confirmButton: 'btn btn-success mr-3',
                                        cancelButton: 'btn btn-danger',
                                        denyButton: 'btn btn-secondary mr-3'
                                    },
                                    buttonsStyling: false
                                })
                                    .then((result) => {
                                        if (result.isConfirmed) {
                                            location.href = location.origin + `/${sessionStorage.getItem('locale')}/login`
                                        }
                                        if (result.isDismissed) {
                                            get_popup('verify_login_for_rent_cancel').then(
                                                popup_cancel => {
                                                    Swal.fire({
                                                        title: popup_cancel.title,
                                                        text: popup_cancel.description,
                                                        icon: popup_cancel.icon,
                                                        showCancelButton: false,
                                                        showDenyButton: false,
                                                        confirmButtonText: I18n.t('global.popup.verify_login_for_rent_cancel_confirm'),
                                                        cancelButtonText: '',
                                                        denyButtonText: '',
                                                        reverseButtons: false,
                                                        allowOutsideClick: false,
                                                        // backdrop: `#354052`,
                                                        backdrop: `rgba(255, 255, 255, 1)`,
                                                        customClass: {
                                                            confirmButton: 'btn btn-success mr-3',
                                                            cancelButton: 'btn btn-danger',
                                                            denyButton: 'btn btn-secondary mr-3'
                                                        },
                                                        buttonsStyling: false
                                                    })
                                                }
                                            )

                                        }
                                        if (result.isDenied) {
                                            location.href = location.origin + `/${sessionStorage.getItem('locale')}/register`
                                        }
                                    })
                        }
                    )*/

                }
            }
        }
    });
})

export let verify_user = () => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/has_autenticated_or_invalid_email`,
        data: {

        },
        type: "GET",
        success: function (data) {
           resolve(data);
        }

    });
})

export let get_authenticated_user_data = () => new Promise((resolve, reject) => {
    setTimeout(function () {
        $('.bootbox').removeAttr('tabindex');
    },2000)
    $.ajax({
        url: `${location.origin}/${sessionStorage.getItem('locale')}/authenticated_user`,
        data: {},
        type: 'GET',
        success: function (user) {
            if (user.success) {
                resolve(user);
            } else {
                let dialog_user_data = bootbox.dialog({
                        title: 'Datos del cliente',
                        message: `<p class='text-danger small'>(*) Campos obligatorios</p><br>
                    <div id="user_form" class="row" data-controller="country">
                        <div class="col-12 mb-2">
                         <input id="user_username" type="text" placeholder="* Nombre de cliente" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_lastname" type="text" placeholder="* Apellidos de cliente" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_email"  type="email" placeholder="* Correo Electrónico" class="form-control required">
                         <small class="text-danger"> Para enviar la notificación y el voucher de la compra.</small> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_passport" type="text" placeholder="* Pasaporte" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <select id="user_country_code" class="form-control required"> </select>
                         <small class="text-danger"> Norma ISO de dos caracteres.</small>
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_city" type="text" placeholder="* Ciudad" class="form-control required"> 
                        </div>
                        <div class="col-12 mb-2">
                         <input id="user_address" type="text" placeholder="* Dirección" class="form-control required"> 
                        </div>
                    </div>
                              `,
                        size: 'large',
                        buttons: {
                            cancel: {
                                label: "Cancelar",
                                className: 'btn-secondary'
                            },
                            ok: {
                                label: "Confirmar datos",
                                className: 'btn-success',
                                callback: function (e) {
                                    let count = 0;
                                    let form_required = $('#user_form').find('.required');
                                    form_required.map((index, element) => {
                                        if (element.value.isEmpty()) {
                                            count += 1;
                                            $(element)[0].classList.remove('is-valid');
                                            $(element)[0].classList.add('is-invalid');
                                        } else {
                                            $(element)[0].classList.add('is-valid');
                                            $(element)[0].classList.remove('is-invalid');
                                        }
                                    });

                                    validate_email(document.getElementById('user_email').value).then(
                                        success => {
                                            if (success) {
                                                if (count > 0) {
                                                    return false;
                                                } else {
                                                    toastr.info('Validando datos del cliente');
                                                    $('button.bootbox-close-button').trigger('click');
                                                    resolve({
                                                        username: document.getElementById('user_username').value,
                                                        lastname: document.getElementById('user_lastname').value,
                                                        email: document.getElementById('user_email').value,
                                                        passport: document.getElementById('user_passport').value,
                                                        country_code: document.getElementById('user_country_code').value.toLowerCase(),
                                                        country_city: document.getElementById('user_city').value,
                                                        address: document.getElementById('user_address').value
                                                    })


                                                }
                                            } else {
                                                let email_user_form = document.getElementById('user_email');
                                                email_user_form.classList.add('is-invalid');
                                                email_user_form.classList.remove('is-valid');
                                                return false;
                                            }
                                        })

                                    return false;

                                }
                            }
                        }
                    })
                ;
            }
        }
    })
})
export default class extends Controller {

    initialize() {
        set_locale().then(
            locale => {
                verification(false).then();
            })
    }
}