import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar, checkSessionVar, checkSessionVarWithData} from './sessionStorage_controller'
import {cancel_shopping_cart} from './wizard_controller'
import {get_popup} from './popup_controller'
import {set_locale} from './localize_controller'
import {get_review} from './review_controller'


import {preferences} from './preferences_controller'
import {catalog} from './catalogs_controller'
import Swal from 'sweetalert2/dist/sweetalert2'
import {blockBody, unblockBody} from './blockUI_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import 'slick-carousel/slick/slick.min'
import {create_array_for_product} from "./b2b_controller";
import bootbox from 'bootbox/bootbox';


let notice_section = null;
let event_section = null;
let map_section_id = null;
let review_section_id = null;
let section_gallery = null;
let review_section = null;
let notice_html = [];
let event_html = [];

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

let notice_event = () => new Promise((resolve, reject) => {
    let event_html_result = ``;
    let event_control = document.getElementsByClassName('event-control');
    if (event_html.length > 0) {
        event_html.map((elem, index) => {
            switch (index % 4) {
                case 0:
                    let active = '';
                    if (index === 0) {
                        active = 'active';
                    } else {
                        event_html_result += `    </div>
                        </div>`;
                    }
                    event_html_result += `<div class="carousel-item ${active}">
                        <div class="carousel-pages">`;
                    event_html_result += elem;
                    break;
                default:
                    event_html_result += elem;
                    break
            }
        })

        if (event_html.length <= 4) {
            for (var i = 0; i < event_control.length; i++) {
                event_control[i].classList.add('ocultar');
            }
        }

    } else {
        if (document.getElementById('event_container') !== null) {
            document.getElementById('event_container').style.display = 'none';
        }
    }
    event_html_result += `    </div>
                        </div>`;

    let notice_html_result = ``;
    let notice_control = document.getElementsByClassName('notice-control');
    if (notice_html.length > 0) {
        notice_html.map((elem, index) => {
            switch (index % 4) {
                case 0:
                    let active = '';
                    if (index === 0) {
                        active = 'active';
                    } else {
                        notice_html_result += `    </div>
                        </div>`;
                    }
                    notice_html_result += `<div class="carousel-item ${active}">
                        <div class="carousel-pages">`;
                    notice_html_result += elem;
                    break;
                default:
                    notice_html_result += elem;
                    break
            }
        })

        if (notice_html.length <= 4) {
            for (var i = 0; i < notice_control.length; i++) {
                notice_control[i].classList.add('ocultar');
            }
        }

    } else {
        if (document.getElementById('notice_container') !== null) {
            document.getElementById('notice_container').style.display = 'none';
        }
    }
    notice_html_result += `    </div>
                        </div>`;

    if (notice_section !== null) {
        notice_section.html(notice_html_result);
    }
    if (event_section !== null) {
        event_section.html(event_html_result);
    }

    resolve({next: true});
})

let review = (id) => new Promise((resolve, reject) => {
    get_review().then(
        respond => {
            let html = '';
            let count = 0;
            if(respond.length > 0){
                respond.map(elem => {
                    let active = '';
                    if(count === 0){
                        active = 'active';
                    }
                    html += `
                <div class="carousel-item ${active}">
                    <div class="testimonial">
                      <div class="content">
                        <span>&rdquo;</span>
                        ${elem.review}
                      </div>
                      <div class="author">
                        <div class="name">${elem.user}</div>
                          <ul class="rating">`;
                    for (let i = 0; i < parseInt(elem.rating); i++) {
                        html += `<li><span class="fa fa-star"></span></li>`;
                    }
                    html += ` </ul>
                      </div>
        
                    </div>
                  </div>
                `;

                    count+=1;
                })
                document.getElementById(id).innerHTML = html;
            }else{
                if(document.getElementById(`to_section_${review_section_id}`) !== null){
                    document.getElementById(`to_section_${review_section_id}`).style.display = 'none';
                }
                document.getElementById('review_pre').style.display = 'none';
            }

        }
    )
})

let aleatori_behavior = () => {
    // var myArray = ['backInLeft', 'backInRight', 'backInUp', 'bounceIn', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'fadeInLeftBig', 'fadeInRightBig', 'fadeInUpBig'];
    var myArray = ['backInUp', 'bounceIn', 'bounceInUp'];
    var rand = Math.floor(Math.random() * myArray.length);
    return myArray[rand];
}

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

let prev = 0;
let next = 0;
let id_array_for_section_with_block = [];

function animation_for_sections() {
    let controller = new ScrollMagic.Controller();

    let image_b_2 = document.getElementById('image_b_2');
    if (image_b_2 !== null) {
        new ScrollMagic.Scene({triggerElement: "#image_b_2", duration: 300})
            .addTo(controller)
            // .on("update", function (e) {
            //     $("#scrollDirection").text(e.target.controller().info("scrollDirection"));
            // })
            .on("enter", function (event) {
            })
            // .on("start end", function (e) {
            //     $("#lastHit").text(e.type == "start" ? "top" : "bottom");
            // })
            .on("progress", function (event) {
                image_b_2.style.top = `-${10 - (event.progress.toFixed(3) * 10)}%`;
            });
    }

    if (document.body.clientWidth > 650) {
        if(id_array_for_section_with_block.length > 0){
            id_array_for_section_with_block.map(id => {
                let section_search_form = document.getElementById(`section_search_form_${id}`);
                if (section_search_form !== null) {
                    new ScrollMagic.Scene({triggerElement: `#block_sec_${id}`, duration: 500, offset: 100})
                        .setPin(`#section_search_form_${id}`)
                        .addTo(controller);
                }
            })

        }

    }


}

function gallery() {
    if(section_gallery !== null){
        let section = `presentation_gallery_${section_gallery}`;
        let controls = `button_magic_masonry_${section_gallery}`;
        let cards = `section_${section_gallery}`;
        let gallery_html = '';



        //BUSCAMOS LOS ELEMENTOS A MOSTRAR
        $.ajax({
            url: `${location.origin}/${sessionStorage.getItem('locale')}/admin/media_galeries/gallery`,
            data: {},
            type: 'GET',
            success: function (data) {
                if (data.length > 0) {
                    data.map((elem, index) => {

                        switch (index % 9) {
                            case 0:
                                let active = '';
                                if (index === 0) {
                                    active = 'active';
                                } else {
                                    gallery_html += `    </div>
                                                    </div>`;
                                }
                                gallery_html += `<div class="carousel-item ${active}" data-interval="60000">
                                                    <div class="magic_grid">`;
                                gallery_html += `<div id="mc_${elem.id}" class="magic_card">
                                <img src="${elem.img}" alt="">
                                <div class="description">
                                  <p>
                                    ${elem.description}
                                  </p>
                                </div>
                                <div class="action">
                                  <a href="javascript:;" onclick="document.getElementById('mc_${elem.id}').classList.toggle('active')"></a>
                                </div>
                              </div>`;
                                break;
                            default:
                                gallery_html += `<div id="mc_${elem.id}" class="magic_card">
                                <img src="${elem.img}" alt="">
                                <div class="description">
                                  <p>
                                    ${elem.description}
                                  </p>
                                </div>
                                <div class="action">
                                  <a href="javascript:;" onclick="document.getElementById('mc_${elem.id}').classList.toggle('active')"></a>
                                </div>
                              </div>`;
                                break
                        }
                    })

                    if (data.length <= 9) {
                        if(document.getElementById(controls) !== null){
                            document.getElementById(controls).style.display = 'none'
                        }


                    }

                    if (document.getElementById(cards) !== null) {
                        document.getElementById(cards).innerHTML = gallery_html;

                        // INICIALIZAMOS EL MAGICGRID PARA LOS MASONRY GRID
                        if (document.getElementsByClassName('magic_grid') !== null && document.getElementsByClassName('magic_grid').length > 0) {
                            let magicGrid = new MagicGrid({
                                container: '.magic_grid',
                                animate: true,
                                gutter: 15,
                                static: true,
                                useMin: true,
                                center: true
                            })
                            magicGrid.listen();
                        }
                    }
                } else {
                    if (document.getElementById(section) !== null) {
                        document.getElementById(section).style.display = 'none';
                    }
                }


            }
        })
    }
}

let checkReservation = async () => {
    if (sessionStorage.getItem('has_reservation') === 'true') {
            $('#show_reservation').css({'display': 'flex'});

    } else {
        $('#show_reservation').css({'display': 'none'});
    }
}
let save_data_pre_reservation = (obj) => new Promise((resolve, reject) => {

    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/admin/products/get_product_data`,
        data: {
            category: obj.dataset.categoryid
        },
        type: 'get',
        success: async (data) => {
            let category = await setSessionVar('category', data.data.code, 'add', false);
            let category_text = await setSessionVar('text_category', data.data.name, 'add', false);
            let day_delay = await setSessionVar('day_delay_rent', data.data.reservation_time, 'add', false);
            let day_min = await setSessionVar('day_min_rent', data.data.min_length_reservation, 'add', false);
            let day_max = await setSessionVar('day_max_rent', data.data.max_length_reservation, 'add', false);
            let description = await setSessionVar('category_description', data.data.description, 'add', false);
            let product = await setSessionVar('product_id', obj.dataset.id, 'add', false);
            let success = 0;
            let fail = 0;

            await [category, category_text, day_delay, day_min, day_max, description, product].map(elem => {
                if (elem.success) {
                    success = success + 1;
                } else {
                    fail = fail + 1;
                }
            })

            resolve({
                success: true,
                success_count: success,
                fail_count: fail

            })
        }

    })


})

let getSeller = async (text) => {
    let p = await preferences(text, 'api');
    setSessionVar('seller', p.value, 'add', false)
}
let cancelReservation = async () => {
    //Metodo por resolver
    /*
    { code: 200, success: true, status: "FAIL", amount: undefined }
setValue_controller.js:65
     */
    cancel_shopping_cart().then(
        async result => {
            if (result.code === 200 && result.success === true) {
                let response = await setSessionVar('', '', 'clear', false);

                if (response.success) {
                    set_locale().then(
                        locale => {
                            location.href = `/${locale.locale}/`;
                        })
                }
            }
        }
    )


}
let staticSection = (context) => new Promise((resolve, reject) => {
    let section_conatiner = $('#section_container');
    let footer_container = $('#footer_section');
    let html = '<div data-controller="word wowjs">';
    let section_show_html = $('#sections_show_navbar');
    let sections_show_in = '';
    let footer = '';
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/admin/sections/json`,
        data: {},
        type: "get",
        success: function (sections) {
            sections.map((section) => {
                if (section_conatiner[0] !== undefined) {
                    if (section.destination === 'body') {
                        if (section.show) {
                            sections_show_in += `<a id="to_section_${section.id}" class="a-link" href="#s_${section.id}" data-action="setValue#saltarA setValue#actionTrigger" data-target="#s_${section.id}">
                                                    ${section.name}
                                                  </a>`
                        }


                        let img = section.thumbnails || section.background_image;
                        let bg_image = section.background_image;
                        html += context.tpl_section(section.id, section.access_url, img, section.helpertext, section.name, section.descriptions, section.backgroud, section.typesection, section.has_divider_top, section.divider_top, section.has_divider_bottom, section.divider_bottom, bg_image);


                    }
                }
                if (footer_container[0] !== undefined) {
                    if (section.destination === 'footer') {
                        footer += context.tpl_section_footer(section.id, section.access_url, section.name, section.typesection);
                    }
                }
            });

            if (section_show_html[0] !== undefined) {
                section_show_html.html(sections_show_in);
            }

            if (section_conatiner[0] !== undefined) {
                html += '</div>';
                section_conatiner[0].innerHTML = html;

                animation_for_sections();
                gallery();

            }

            if (footer_container[0] !== undefined) {
                footer_container.html(footer);
            }


            resolve({
                next: true
            })

        }
    })
});
let staticPage = (context) => new Promise((resolve, reject) => {
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/admin/pages/json`,
        data: {format: "json"},
        type: "get",
        success: function (pages) {
            let html = '';
            let count_notice = 0;
            let count_event = 0;
            pages.map((page) => {
                if (page.show) {
                    let url = '';
                    let section_body = $('#section_' + page.section.id);

                    if (page.isExternaUrl) {
                        url = page.access_url;
                    } else {
                        if (page.access_url === null || page.access_url === "" || page.access_url === undefined) {
                            url = 'javascript:;';
                        } else {
                            url = location.origin + `/${sessionStorage.getItem('locale')}/pages/ver/` + page.access_url;
                        }
                    }

                    let image = ''
                    let icon = '';
                    let description = '';

                    // EDITAR EL COMPORTAMIENTO DEL ICONO EN TODAS LAS PAGINAS
                    if (!page.icon.isEmpty()) {
                        if (page.icon.includes('fa-')) {
                            icon = `<span class="fa ${page.icon}"></span>`;
                        } else {
                            if(page.icon.includes('icon-')){
                                icon = `<span class="${page.icon}"></span>`;
                            }else{
                                icon = `<img src="${page.icon}" alt="" class=''>`;
                            }

                        }
                    }

                    if (page.section.destination === 'body') {



                        if (page.has_thumbnails) {
                            image = page.thumbnails
                        } else {
                            if (page.has_image) {
                                image = page.background_image
                            }
                        }


                        if (page.short_description !== null) {
                            description = page.short_description;
                        }

                        if (section_body !== undefined) {
                            if (page.section.typesection === 'presentation-notice-more-events') {
                                notice_section = $('#notice_page_' + page.section.id);
                                event_section = $('#event_page_' + page.section.id);

                                if (page.tipo === 'notice') {

                                    notice_html.push(context.tpl_page(page.id, url, page.isExternaUrl, page.name, description, page.section.typesection, image, icon, count_notice, page.has_divider_top, page.divider_top, page.has_divider_bottom, page.divider_bottom));

                                }
                                if (page.tipo === 'event') {
                                    event_html.push(context.tpl_page(page.id, url, page.isExternaUrl, page.name, description, page.section.typesection, image, icon, count_event, page.has_divider_top, page.divider_top, page.has_divider_bottom, page.divider_bottom));
                                    count_event += 1;
                                }
                            } else {
                                section_body.append(context.tpl_page(page.id, url, page.isExternaUrl, page.name, description, page.section.typesection, image, icon, 0, page.has_divider_top, page.divider_top, page.has_divider_bottom, page.divider_bottom));
                            }
                        }
                    }

                    if (page.section.destination === 'footer') {
                        if (section_body !== undefined) {
                            section_body.append(context.tpl_page_footer(page.id, url, page.isExternaUrl, page.name, icon));
                        }
                    }

                }
            })


            resolve({
                next: true
            });
        }
    })
})


export default class extends Controller {

    initialize() {
        set_locale().then(
            locale => {
                // const btn_assistant_line = $('#btn_assistant_line');
                // if (location.pathname.includes('help_me')) {
                //     btn_assistant_line[0].style.display = 'none';
                // }
                if (sessionStorage.hasOwnProperty('need_clean')) {
                    if (sessionStorage.getItem('need_clean') === "true" && sessionStorage.getItem('inPayProccess') === "true" && location.pathname.includes("/payment/return")) {
                        setSessionVar('', '', 'clear', false);
                    }
                }

                //Disabled input
                $('#date_collect').attr('disabled', true);
                $('#time_collect').attr('disabled', true);
                $('#date_delivery').attr('disabled', true);
                $('#time_delivery').attr('disabled', true);

                getSeller('c2e_api_key');
                checkReservation().then();

                let container = $('#catalogo_container');
                if (container !== undefined && (location.pathname === `/${locale.locale}` || location.pathname === `/`)) {
                    preferences('show_catalog', 'modules').then(
                        showCatalog => {
                            if (showCatalog.active)
                                catalog().then(
                                    catalogo => {
                                        preferences('show_form_in_catalog', 'modules').then(
                                            showFormInCatalog => {
                                                let html = '';
                                                if (catalogo.success) {
                                                    let data = catalogo.data;

                                                    data.map(product => {
                                                        html += this.tpl_catalog(product.id, product.category, product.category_id, product.picture, product.price, product.description, showFormInCatalog.active);
                                                    })
                                                }

                                                container.html(html);
                                            }
                                        );

                                    }
                                )
                        })
                }


                const context = this;
                staticSection(context).then(
                    result => {
                        if (result.next) {
                            if (review_section !== null) {
                                review(review_section).then(
                                    review => {
                                        console.log(review);
                                    }
                                );
                            }

                            staticPage(context).then(
                                page_result => {
                                    if (page_result.next) {

                                        notice_event().then(
                                            notice_event_result => {
                                                unblockBody();
                                            }
                                        );
                                    }
                                }
                            )
                        }
                    }
                )

            })

    }

    connect() {
    }

    prev(e) {

        let element = e.target.dataset.target;
        if (prev < 0) {
            next -= (window.innerWidth - 20);
            prev += (window.innerWidth - 10);
            $('#' + element)[0].style.left = `${prev}px`;
        }
    }

    next(e) {
        next += (window.innerWidth - 20);
        prev -= (window.innerWidth - 10);
        let element = e.target.dataset.target;
        $('#' + element)[0].style.left = `${-next}px`;
    }

    setSessionKey(e) {
        setSessionVar(e.target.dataset.name, e.target.value, 'add', e.target.dataset.isdate).then(
            data => {
                create_array_for_product(e.target.dataset.name,e.target.value,'add');
            }
        );
    }


    tpl_section(id, url, picture, helpertext, name, description, background, type, has_divider_top = false, divider_top = '', has_divider_bottom = false, divider_bottom = '', bg_image = '') {

        let dt = '';
        let db = '';
        let margin_y = 'margin-y-no-divider';


        let html = `<section id="s_${id}"><div class="row no-print ${background}">`;
        switch (type) {
            case 'presentation-with-many-image-and-form':
                id_array_for_section_with_block.push(id);
                html += `
                 <div class="initial-section">
                    <div class="section-container">
                     <div class="block_primary">
                      <div class="section-information">
                        <div class="section-information-title">
                          <h1>
                            ${name}
                          </h1>
                          <p>${helpertext}</p>
                        </div>
                        <div class="section-information-button">
                          <a href="javascript:;" class="decoration_none" data-action="setValue#saltarA" data-target="#block_sec_${id}">
                             <span data-target="#block_sec_${id}">
                               ${I18n.t ('main.body.catalog.rent_btn')}
                             </span>
                          </a>
                        </div>
                      </div>
                      <div class="section-image">
                        <div class="section-image-background" style="--pict: url(${picture})">
                        </div>
                        <div class="section-image-overlay" style="--overl: url(${divider_top})">
                        </div>
                      </div>
                    </div>
                     <div id="block_sec_${id}" class="block-secondary">
                      <div class="section-bottom-image">
                          <div id="image_b_2" class="section-bottom-image-background image-parallax-efect" style="--pict-two: url(${bg_image})">
                          </div>
                          <div class="section-bottom-image-overlay" style="--overl-two: url(${divider_bottom})">
                          </div>
                      </div>
                      <div id="section_search_form_${id}" class="section-form">
                        <div id="box-form" class="box-form" data-controller="preferences">
        <!--                        TAB PARA EL HEADER DEL FORM-->
                         <ul class="nav nav-tabs" id="home_form" role="tablist">
                         
                            <li class="nav-item" role="presentation">
                              <button class="nav-link active button_with_svg_icon" 
                              id="events-tab" data-toggle="tab" 
                              data-target="#events" data-form-type="events" 
                              data-action="preferences#set_form_type" type="button" 
                              role="tab" aria-controls="home" aria-selected="true">
                              <svg viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48" fill="#000000"><g id="SVGRepo_bgCarrier1" stroke-width="0"></g><g id="SVGRepo_tracerCarrier1" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier1"> <path fill="#CFD8DC" d="M5,38V14h38v24c0,2.2-1.8,4-4,4H9C6.8,42,5,40.2,5,38z"></path> <path fill="#F44336" d="M43,10v6H5v-6c0-2.2,1.8-4,4-4h30C41.2,6,43,7.8,43,10z"></path> <g fill="#B71C1C"> <circle cx="33" cy="10" r="3"></circle> <circle cx="15" cy="10" r="3"></circle> </g> <g fill="#B0BEC5"> <path d="M33,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C35,3.9,34.1,3,33,3z"></path> <path d="M15,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C17,3.9,16.1,3,15,3z"></path> </g> <g fill="#90A4AE"> <rect x="13" y="20" width="4" height="4"></rect> <rect x="19" y="20" width="4" height="4"></rect> <rect x="25" y="20" width="4" height="4"></rect> <rect x="31" y="20" width="4" height="4"></rect> <rect x="13" y="26" width="4" height="4"></rect> <rect x="19" y="26" width="4" height="4"></rect> <rect x="25" y="26" width="4" height="4"></rect> <rect x="31" y="26" width="4" height="4"></rect> <rect x="13" y="32" width="4" height="4"></rect> <rect x="19" y="32" width="4" height="4"></rect> <rect x="25" y="32" width="4" height="4"></rect> <rect x="31" y="32" width="4" height="4"></rect> </g> </g></svg>
                               <p class="d-none d-md-inline">Eventos</p>
                               </button>
                              
                            
                            </li>
                            <input id="search_form_type" type="hidden" value="events">
                          </ul>
                          
        <!--                  CUERPO DE LOS TAB-->
                          <div class="tab-content" id="home_form_content">
                            <div class="tab-pane fade show active" id="events" role="tabpanel" aria-labelledby="home-tab">
                              <div class="row">
                              <div class="col-md-12">
                                <div class="row p-0" >
                                <div class="form-group col-md-4">
                                  <label for="event_product">Grupo</label>
                                  <select id="event_product" class="form-control required"></select>
                                  <span class="helper-text small"></span>
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="event_modality">Modalidad</label>
                                  <select id="event_modality" class="form-control required"></select>
                                  <span class="helper-text small"></span>
                                </div>
                                </div>
                                <div class="row p-0" >
                                <div class="form-group col-md-4">
                                  <label for="event_category_person">Categoría</label>
                                  <select id="event_category_person" class="form-control required"></select>
                                  <span class="helper-text small"></span>
                                </div>
                                <div class="form-group col-md-4 ocultar">
                                  <label for="event_entity">Nombre de entidad</label>
                                  <input id="event_entity" class="form-control" type="text">
                                  <span class="helper-text small"></span>
                                </div>
                                 <div class="form-group col-md-4">
                                  <label for="event_amount">Cantidad de personas</label>
                                  <input id="event_amount" class="form-control required" type="number">
                                  <span class="helper-text small"></span>
                                </div>
                              </div>
                              </div>
                              <div class="col-md-12">
                                    <div class="d-flex justify-content-end" style="width: 100%;">
                                      <button id="event_btn" class="form_send" type="button" data-action="b2b#create_reservation" data-validate="events" data-addtocart="true">
                                        <span data-preferences-target="textButton" data-validate="events" data-addtocart="true"></span>
                                      </button>
                                    </div>
                                 </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                    </div>
                  </div>
                `;
                break;
            case 'presentation-information':
                html += ` <div class="presentation-information">
                              <div class="presentation-header">
                                <div class="header-helper">
                                    ${helpertext}
                                </div>
                                <div class="header-image" >
                                      <img src="${picture}" alt="">
                                </div>
                              </div>
                              <div class="presentation-body">
                                <div class="title">${name}</div>
                                <div class="divider"></div>
                                <div class="content">${description}</div>
                              </div>
                            </div>`
                break;
            case 'presentation-modalities-list':
                html += `<div class="presentation-modalities-list" style="--picture: url(${picture})">
                              <div class="presentation-container">
                                <div class="presentation-left">
                                  <div class="helper">${helpertext}</div>
                                  <div class="square"></div>
                                </div>
                                <div class="presentation-right">
                                  <div class="title">${name}</div>
                                  <div id="section_${id}" class="page-list"></div>
                                </div>
                              </div>
                              <div class="presentation-bottom-image">
                                <img src="${divider_bottom}" alt="">
                              </div>
                            </div>`
                break;
            case 'presentation-notice-more-events':
                html += `
                  <div class="presentation-notice-more-events" data-controller="tooltip">
                      <div class="presentation-header">
                        <div class="side-left">
                          <div class="title">${name}</div>
                          <div class="divider"></div>
                          <div class="helper">${helpertext}</div>
                        </div>
                        <div class="side-right">
                          <div class="background">
                            <img src="${bg_image}" alt="">
                          </div>
                          <div class="thumbnail">
                            <img src="${picture}" alt="">
                          </div>
                        </div>
                      </div>
                      <div class="presentation-body">
                        <div id="event_container" class="events">
                          <div class="title">${I18n.t('global.section_an_page.events')}</div>
                          <div id="events" class="carousel slide" data-ride="carousel" data-interval="true" data-pause="hover" data-touch="true">
                            <div id="event_page_${id}" class="carousel-inner pages">
                             
                            </div>
                            <button class="carousel-control-prev event-control" type="button" data-target="#events" data-slide="prev">
                              <span class="fa fa-arrow-left" aria-hidden="true"></span>
                            </button>
                            <button class="carousel-control-next event-control" type="button" data-target="#events" data-slide="next">
                              <span class="fa fa-arrow-right" aria-hidden="true"></span>
                            </button>
                            <div class="swipe-btn d-md-none">
                              <span aria-describedby="tooltip" class="tooltip-button" data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="swipe_button">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hand-move" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                     <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
                                     <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5"></path>
                                     <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
                                     <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
                                     <path d="M2.541 5.594a13.487 13.487 0 0 1 2.46 -1.427"></path>
                                     <path d="M14 3.458c1.32 .354 2.558 .902 3.685 1.612"></path>
                                  </svg>
                              </span>
                              <span id="swipe_button" role="tooltip" class="text-tooltip" style="font-size: .85em; width: 250px !important; padding: 20px; text-align: start;background: var(--txt-highlight-secondary) !important;box-shadow: 0 0 25px rgba(0,0,0,.5);">
                                   <span class="helper-text">Puede desplazar la pantalla a la izquierda o la derecha para ver mas noticias o eventos</span>
                                   <span id="arrow" data-popper-arrow></span>
                                </span>
                    
                            </div>
                          </div>
                        </div>
                        <div id="notice_container" class="notices">
                          <div class="title">${I18n.t('global.section_an_page.notices')}</div>
                          <div id="notices" class="carousel slide" data-ride="carousel" data-interval="false" data-touch="true">
                            <div id="notice_page_${id}" class="carousel-inner pages">
                              
                            </div>
                            <button class="carousel-control-prev notice-control" type="button" data-target="#notices" data-slide="prev">
                              <span class="fa fa-arrow-left" aria-hidden="true"></span>
                            </button>
                            <button class="carousel-control-next notice-control" type="button" data-target="#notices" data-slide="next">
                              <span class="fa fa-arrow-right" aria-hidden="true"></span>
                            </button>
                            <div class="swipe-btn d-md-none">
                              <span aria-describedby="tooltip" class="tooltip-button" data-action="mouseenter->tooltip#tooltip mouseleave->tooltip#tooltip touchstart->tooltip#tooltip touchend->tooltip#tooltip" data-tooltip="swipe_button_notices">
                              </span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hand-move" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
                                <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5"></path>
                                <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
                                <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
                                <path d="M2.541 5.594a13.487 13.487 0 0 1 2.46 -1.427"></path>
                                <path d="M14 3.458c1.32 .354 2.558 .902 3.685 1.612"></path>
                              </svg>
                    
                              <span id="swipe_button_notices" role="tooltip" class="text-tooltip" style="font-size: .85em; width: 250px !important; padding: 20px; text-align: start;background: var(--txt-highlight-secondary) !important;box-shadow: 0 0 25px rgba(0,0,0,.5);">
                                   <span class="helper-text">Puede desplazar la pantalla a la izquierda o la derecha para ver mas noticias o eventos</span>
                                   <span id="arrow" data-popper-arrow></span>
                                </span>
                    
                            </div></div>
                        </div>
                      </div>
                    </div>
                `;
                break;
            case 'presentation-with-image-for-page':

                if (has_divider_top) {
                    dt = `<div class="col-md-12 presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div>`;
                }

                html += `
                <div class="col-md-12 presentation-with-image-for-page">
                    <div class="row">
                        ${dt}
                    </div>
               
                    <div class="row">
                      <div class="col-md-12">
                        <div class="presentation-title last-word-change">
                          ${name}
                        </div>
                      </div>
                    </div>
                    <div id="section_${id}" class="pages-wrapper"></div>
                    <div class="presentation-footer">
                      <a onclick="location.href = '/${sessionStorage.getItem('locale')}/sections/ver/${url}'" class="btn-presentation-footer">
                        ${I18n.t('main.body.read_more')}
                      </a>
                    </div>
                  </div>
                `
                break;
            case 'presentation_with_image_left':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }
                html += `
                <div class="col-md-12">
                  <div class="presentation-with-image-left">
                    ${dt}
                    <div class="wrapper ${margin_y}">
                        <div class="image-container">
                      
                        <img src="${picture}" alt="${name}">
                        </div>
                        <div class="info-container">
                        <div class="helper-text">
                        ${helpertext}
                        </div>
                        <div class="title-text last-word-change">
                        ${name}
                        </div>
                        <div class="divider"></div>
                        <div class="description-text">
                        ${description}
                        </div>
                        <div class="button_action">
                            <button onclick="location.href = '/${sessionStorage.getItem('locale')}/sections/ver/${url}'"> ${I18n.t('global.main.read_more')}</button>
                        </div>
                        </div>
                        <div class="page-wrapper">
                            <div class="divider-vertical"></div>
                            <div class="">
                        <ul id="section_${id}" class="pages"></ul>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_5_columns':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }
                html += `
                <div class="col-md-12">
                  <div class="presentation-with-pages-by-column">
                   ${dt}
                   <div class="wrapper ${margin_y}">
                        <div class="presentation-title">
                          <div class="presentation-text last-word-change"> ${name}</div>
                          <div class="divider"></div>
                          <div class="presentation-helper"> ${helpertext}</div>
                        </div>
                       
                        <div id="section_${id}" class="presentation-body slick-five-column">
                        </div>
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_3_columns':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation_with_image_3_columns">
                  ${dt}
                   <div class="wrapper ${margin_y}">
                        <div class="presentation-title">
                          <div class="presentation-text last-word-change" > ${name}</div>
                          <div class="divider"></div>
                           <div class="presentation-helper" > ${helpertext}</div>
                        </div>
                        <div id="section_${id}" class="presentation-body slick-three-column"></div>
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-whit-image-right':

                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }

                html += `<span id="section_${id}_textname" class="ocultar">${name}</span>`;
                if (window.innerWidth <= 640) {
                    html += `
                <div class="col-md-12">
                  <div class="presentation-whit-image-right">
                  ${dt}
                  <div class="wrapper ${margin_y}">
                    <div class="page-container">
                      <div class="page-header last-word-zoom" data-target="text_section_${id}" data-source="section_${id}_textname">${name}</div>
                      <div class="divider"></div>
                       <div class="image-container">
                      <div id="text_section_${id}" class="text-extra"></div>
                         <div class="image-wrapper">
                              <img src="${picture}" alt="${name}">
                            </div>
                        </div>
                      <div class="page-helper-text">${helpertext}</div>
                      <div class="page-wrapper">
                        <ul id="section_${id}" class="page-list">
            
                        </ul>
                      </div> 
                      <div class="page-buttons">
                        <button onclick="location.href =location.origin+'/${sessionStorage.getItem('locale')}/sections/ver/${url}'">${I18n.t('global.main.read_more')}</button>
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
                `;
                } else {
                    html += `
                <div class="col-md-12">
                  <div class="presentation-whit-image-right">
                  ${dt}
                  <div class="wrapper ${margin_y}">
                  <div class="page-container">
                      <div class="page-header last-word-zoom" data-target="text_section_${id}" data-source="section_${id}_textname">${name}</div>
                      <div class="divider"></div>
                      <div class="page-helper-text">${helpertext}</div>
                      <div class="page-wrapper">
                        <ul id="section_${id}" class="page-list">
            
                        </ul>
                      </div> 
                      <div class="page-buttons">
                       <button onclick="location.href =location.origin+'/${sessionStorage.getItem('locale')}/sections/ver/${url}'">${I18n.t('global.main.read_more')}</button>
                      </div>
                    </div>
                    <div class="image-container">
                      <div id="text_section_${id}" class="text-extra"></div>
                         <div class="image-wrapper">
                              <img src="${picture}" alt="${name}">
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
                `;
                }
                break;
            case 'presentation_with_image_2_columns':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }
                html += `
                 <div class="col-md-12 section">
                  <div class="presentation_with_image_2_columns">
                   ${dt}
                    <div id="section_${id}" class="page-container ${margin_y}">
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-paralax-with-3-columns':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }

                if (has_divider_bottom) {
                    db = `<div class="presentation-divider-bottom"><div class="presentation-divider-bottom" style="--img-dv-top: url(${divider_bottom})"></div></div>`;
                }
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation-paralax-with-3-columns">
                    ${dt}
                    <div class="wrapper">
                         <div class="backgroud-paralax" style="background-image: url('${picture}');">
                          <div class="presentation-wrapper ${margin_y}">
                            <div class="presentation-helper-text">${helpertext}</div>
                            <div class="presentation-header">
                              ${name}
                            </div>
                            <div class="presentation-description">
                              <div class="description-text">
                                ${description}
                              </div>
                            </div>
                            <div id="section_${id}" class="presentation-pages"></div>
                          </div>
                        </div>          
                    </div>
                   ${db}
                  </div>       
                </div>
                `;
                break;
            case 'presentation-with-notice':
                if (has_divider_top) {
                    margin_y = 'margin-y'
                    dt = `<div class="presentation-divider"><div class="presentation-divider-top" style="--img-dv-top: url(${divider_top})"></div></div>`;
                }
                html += `
                   <div class="col-md-12 section">
                    <div class="presentation-with-notice">
                         ${dt}
                      <div class="notice-container ${margin_y}">
                      <div class="notice-header">
                        <div class="notice-title last-word-change">${name}</div>
                        <div class="notice-divider"></div>
                        <div class="notice-helper-text">${helpertext}</div>
                      </div>
                        <div class="notice-body">
                         <div id="section_${id}" class="notice-list slick_notice"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                        <div class="container-fluid">
                          <div class="presentation-comercial-client">
                              <div class="presentation-title col-12 col-md-12">
                                <div class="presentation-text last-word-change" data-controller="word">
                                  ${name}
                                </div>
                                <div class="mb-3">
                                ${helpertext}
                                </div>
                                <div class="divider"></div>
                              </div>
                              <div id="section_${id}" class="presentation-body row"></div>
                            </div>
                       
                        
                        </div>
                `;
                break;
            case 'presentation-with-map':
                map_section_id = id;
                html += `
                       <div class="presentation-with-map">
                          <div class="left">
                            <div id="overlay_map_load" class="overlay_map_load_left"></div>
                            <div class="for_map" data-controller="map">
                              <div id="map" data-id="${id}"></div>
                            </div>
                          </div>
                          <div class="right">
                            <div class="title">${name}</div>
                            <div class="divider"></div>
                            <div class="helper">${helpertext}</div>
                          </div>
                        </div>
                `;
                break;
            case 'presentation-review':
                review_section_id = id;
                review_section = `section_${id}`;

                html += `
                <div id="review_pre" class="presentation-review">
                  <div class="background" style="--picture: url(${picture})">
                
                  </div>
                  <div class="presentation-body">
                    <div class="title">${name}</div>
                    <div class="body" data-controller="carousel">
                      <div id="review" class="carousel slide carousel-fade modifi_behavior_carousel" data-ride="carousel" data-interval="false" data-touch="true">
                        <div id="section_${id}" class="carousel-inner"></div>
                        <button class="carousel-control-prev" type="button" data-target="#review" data-slide="prev">
                            <span class="fa fa-arrow-left" aria-hidden="true"></span>
                          </button>
                          <button class="carousel-control-next" type="button" data-target="#review" data-slide="next">
                            <span class="fa fa-arrow-right" aria-hidden="true"></span>
                          </button>

                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                   <img src="${divider_bottom}" alt="">
                  </div>
                </div>
                `;
                break;
            case 'presentation-gallery':

                section_gallery = id;

                html += `
                <div id="presentation_gallery_${id}" class="presentation-gallery">
                  <div class="row">
                    <div class="col-12 col-md-4" >
                      <div class="visor-image">
                        <div class="title">${name}</div>
                        <div class="helper-text">
                          ${helpertext}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-8">
                      <div id="presentation_magic_masonry_${id}" class="carousel slide carousel-fade" data-ride="carousel" data-touch="true" data-interval="false">
                        <div id="section_${id}" class="carousel-inner">
                         
                        </div>
                        <div id="button_magic_masonry_${id}">
                        <button class="carousel-control-prev" type="button" data-target="#presentation_magic_masonry_${id}" data-slide="prev">
                          <span class="fa fa-arrow-left"></span>
                        </button>
                        <button class="carousel-control-next" type="button" data-target="#presentation_magic_masonry_${id}" data-slide="next">
                          <span class="fa fa-arrow-right"></span>
                        </button>
                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
                `;
                break;
        }

        html += `</div></section>`;

        return html;
    }

    tpl_section_footer(id, url, name, type) {
        let html = ``;
        switch (type) {
            case 'footer':
                html += `
                   <div class="footer-section">
                    <div class="footer-section-title">
                      ${name}
                    </div>
                    <div id="section_${id}" class="section-page-wrapper">
                    </div>
                  </div>
                `;
                break;
        }
        return html;
    }

    tpl_page(id, url, isExternal, name, description, type, picture, icon, count = 0, has_div_top = false, div_top = '', has_dv_bottom = false, dv_bottom = '') {
        let des = description || ' ';
        let html = ``;
        let dt = '';
        let db = '';

        switch (type) {
            case 'presentation-with-image-for-page':

                html += `
                 <div class="page-item">
                       ${dt}
                        <a href="${url}">
                          <div class="image-page">
                            <img src="${picture}">
                          </div>
                          <div class="text-page">
                            ${name}
                          </div>
                        </a>
                        ${db}
                      </div>
                      
                `;
                break;
            case 'presentation-modalities-list':
                html = `
                   <a href="${url}" class="page">
                        <span class="icon">
                          ${icon}
                        </span>
                        <span class="page-name">${name}</span>
                      </a>
                `;
                break;
            case 'presentation-notice-more-events':
                html += `
                   <div class="page">
                        <div class="left">
                          <img src="${picture}" alt="">
                          <a class="a-link" href="${url}">${I18n.t('main.body.read_more')}</a>
                        </div>
                        <div class="right">
                          <div class="title">${name}</div>
                          <div class="helper">${description}</div>
                        </div>
                      </div>    
                `;
                break;
            case 'presentation_with_image_left':
                html = `
                    <li class="page-section">
                    <div class="page-icon">
                        ${icon}
                    </div>
                    <div class="page-container">
                        <div class="page-title">
                            ${name}
                        </div>
                        <div class="divider"></div>
                        <div class="page-body">
                           ${des}
                        </div>
                    </div>
                </li>`;
                break;
            case 'presentation_with_image_5_columns':
                html = ` <div class="">
                     ${dt}
                    <div class="page-card">
                      <div class="page-slider">
                        <div class="page-card-image">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="page-card-body">
                            <div class="page-title">
                                ${name}
                            </div>
                            <div class="page-divider"></div>
                            <div class="page-helper">
                                ${description}
                            </div>
                            <div class="page-buttons">
                                 <button onclick="location.href = '${url}'">${I18n.t('global.main.read_more')}</button>
                            </div>
                        </div>
                        </div>
                   
                  </div>
                </div>
                 ${db}
                </div>`;
                break;
            case 'presentation_with_image_3_columns':
                html = ` 
                 <div class="">
                  ${dt}
                 <div class="page-card">
                                  <div class="page-slider">
                                    <div class="page-card-image">
                                      <img src="${picture}" alt="${name}" onclick="location.href = '${url}'">
                                    </div>
                                    <div class="page-title">
                                      ${name}
                                    </div>
                                    <div class="page-divider"></div>
                                  </div>
                                </div>
                                 ${db}
                </div>
                 `;
                break;
            case 'presentation-whit-image-right':
                html = ` <li>
                    <div class="page-icon">
                      ${icon}
                    </div>
                    <div class="page-container">
                      <div class="page-title">${name}</div>
                      <div class="divider-page"></div>
                    </div>
                  </li>`;
                break;
            case 'presentation_with_image_2_columns':
                let text = des.slice(0, 250);
                html = ` <div class="page">
                ${dt}
                <div class="page-image-container">
                <div class="overlay"></div>
                  <img src="${picture}" alt="${name}">
                </div>
                <div class="page-body-container">
                  <div class="body-header">${name}</div>
                  <div class="body-text">${text}...</div>
                  <div class="body-button">
                    <button onclick="location.href = '${url}'">${I18n.t('global.main.read_more')}</button>
                  </div>
                </div>
                 ${db}
              </div>`;
                break;
            case 'presentation-paralax-with-3-columns':
                html = `
                  <div class="page">
                    <button onclick="location.href = '${url}'">${name}</button>
                  </div>
                `;
                break;
            case 'presentation-with-notice':
                html += `
                    <div class="">
                     ${dt}
                     <div class="notice">
                        <div class="n-picture">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="n-divider"></div>
                        <div class="n-wrapper">
                        <div class="n-title">
                          ${name}
                        </div>               
                        <div class="n-body">
                          ${des}
                        </div>
                        <div class="n-footer">
                          <button type="button" onclick="location.href = '${url}'">${I18n.t('global.main.read_more')}</button>
                        </div>   
                        </div>
                                     
                      </div>
                       ${db}
                    </div>
                 
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                               <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                <div class="card-comercial">
                                  <div class="image-container">
                                    <img src="${picture}" alt="${name}">
                                  </div>
                                  <div class="image-text d-flex justify-content-center">
                    
                                    <div class="divider"></div>
                                  </div>
                                </div>
                              </div>
                                 `;
                break;

        }


        return html;
    }

    tpl_catalog(id, category, category_id, picture, price, description, showButton) {
        let buyBtn = '';
        let btn_text = I18n.t('main.body.catalog.rent_btn');
        if (showButton) {
            buyBtn = `<a href="javascript:;" class="buy-btn" data-action="setValue#pre_reservation" data-id="${id}"   data-category="${category}" data-categoryid="${category_id}" data-description="${description}"  >${btn_text}</a>`;
        }
        return `<div class="">
            <div class="box">
              <div class="slider-img">
                <img src="${picture}" alt="${category}">
                <div class="overlay">
                  ${buyBtn}
                </div>
              </div>
               <div class="detail-btn">
               ${buyBtn}
               </div>
              <div class="details-box">
                <div class="type">
                  <a href=""></a>
                  <span>${category}</span>
                </div>

                <a href="" class="price">
                   ${I18n.t('main.body.catalog.from')} $${price}.00
                </a>
              </div>
             
               <div class="detail-description">
                <div class="title-description">
                ${I18n.t('main.body.catalog.description')}
                </div>
                <div class="desciption-body">
                ${description}
                </div>
                
            </div>
            </div>
          </div>`;
    }

    tpl_page_footer(id, url, isExternal = false, name, icon = null) {
        let html = '';

        if (icon !== null && icon.includes('fa')) {
            icon = `fa ${icon}`;
        }



        if (isExternal) {
            html = ` <div class="footer-link">
                          <a href="${url}" target="_blank">${icon} ${name}</a>
                        </div>
            `;
        } else {
            html = ` <div class="footer-link">
                          <a href="${url}" >${icon} ${name}</a>
                        </div>
            `;
        }
        return html
    }

    print(e) {
        set_locale().then(
            locale => {
                location.href = location.origin + `/${locale.locale}/histories/${e.target.dataset.history}.pdf`

            }
        )
    }

    pre_reservation(e) {
        save_data_pre_reservation(e.target).then(r => {
                if (r.fail_count === 0) {
                    location.href = `/${sessionStorage.getItem('locale')}/pre_reservation`;
                }
            }
        );


    }

    cancel_reservation() {
        get_popup('cancel_rent').then(
            cancel_rent => {
                Swal.fire({
                    title: cancel_rent.title || 'Cancelar reserva',
                    text: cancel_rent.description || "¿Usted desea cancelar su reserva?",
                    icon: cancel_rent.icon || 'question',
                    showCancelButton: true,
                    confirmButtonText: I18n.t('global.popup.cancel_rent_confirm'),
                    cancelButtonText: I18n.t('global.popup.cancel_rent_cancel'),
                    reverseButtons: true,
                    allowOutsideClick: false,
                    // backdrop: `#354052`,
                    backdrop: `rgba(255, 255, 255, 1)`,
                    customClass: {
                        confirmButton: 'btn btn-secondary',
                        cancelButton: 'btn btn-success margin-right-15'
                    },
                    buttonsStyling: false
                })
                    .then(async (result) => {
                        blockBody();
                        if (result.isConfirmed) {
                            await cancelReservation();
                        }
                        if (result.isDismissed) {
                            location.href = `/${sessionStorage.getItem('locale')}/reserva`
                        }
                    })
            }
        )
    }

    hide_reservation() {
        let r = $('#reservation');
        r.removeClass('show_reservation');
        r.addClass('hidde_reservation')
    }

    show_hide_element(e) {
        let hide = $('#' + e.target.dataset.hide);
        let show = $('#' + e.target.dataset.show);

        hide.addClass('ocultar');
        show.removeClass('ocultar');
    }

    assistan_line(e) {
        let url = location.origin + `/${sessionStorage.getItem('locale')}/help_me`;
        if (localStorage.hasOwnProperty('chat_room')) {
            url = location.origin + `/${sessionStorage.getItem('locale')}/help_me?id=` + sessionStorage.getItem('chat_room');
        }

        location.href = url;
    }

    saltarA(e) {

        let element = $(e.target.dataset.target);
        let top = parseFloat(element.offset().top) - 87;
        $("html, body").animate({scrollTop: top}, 1000);
    }

    actionTrigger(e) {
        $(`#button_toogle_menu_bar`).trigger('click');
    }

    clear_session(e){
        alert('Vamos a limpiar la section');
        setSessionVar('','','clear',false).then(
            clear => {
                location.href = location.origin
            }
        )
    }


    delete_element(e){
        bootbox.confirm({
            size: 'small',
            message: '¿Desea elimiar este elemento?',
            buttons: {
                confirm: {
                    className: 'btn-danger',
                    label: 'Eliminar'
                },
                cancel: {
                    className: 'btn-outline-secondary',
                    label: 'Cancelar'
                }
            },
            callback: function(result) {
                if(result){
                    $.ajax({
                        url: e.target.dataset.url,
                        data: {},
                        type: 'GET',
                        success: function (data) {
                            if(data.success){
                                toastr.success(data.msg, data.code);
                                location.reload();
                            }
                        }
                    })
                }
            }
        });
    }
}