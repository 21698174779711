import {Controller} from "stimulus"
import $ from 'jquery';

import popper from '@popperjs/core'



export default class extends Controller {
    initialize(){
        if(sessionStorage.hasOwnProperty('enable_analytics') && sessionStorage.getItem('enable_analytics') === 'true'){
            let cookie_card = $('#cookie_card');
            document.getElementsByTagName('body')[0].style.position = 'relative';
            cookie_card[0].classList.remove('active');
            setTimeout(function () {
                cookie_card.remove();
            },100);

        }else{
            document.getElementsByTagName('body')[0].style.position = 'fixed';
        }

    }

    connect() {
        let analitics_box = $('#enable_analitics');

        analitics_box.on('click',function () {
          $.ajax({
              url: location.origin+'/enable_analytics',
              data: {},
              type: 'POST',
              success: function (data) {
                  if(data.success){
                      let cookie_card = $('#cookie_card');
                      sessionStorage.setItem('enable_analytics','true');
                      document.getElementsByTagName('body')[0].style.position = 'relative';
                      cookie_card[0].classList.remove('active');
                      setTimeout(function () {
                          cookie_card.remove();
                      },100);
                  }
              }
          })
       })
    }
}