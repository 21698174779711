import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import {preferences} from "./preferences_controller";
import {blockElementsById} from "./blockUI_controller";
import {create_array_for_product} from "./b2b_controller";

export let get_contract=(box_block = null,dealer )=>new Promise((resolve, reject)=>{
   preferences('url_contracts','url').then(
       result => {
           $.ajax({
               url: location.origin+'/contracts_by_dealer',
               data: {
                   dealer: dealer || create_array_for_product('dealer_code','','show')
               },
               beforeSend: function () {
                 if(box_block !== null){
                     blockElementsById(box_block,`<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t('global.main.form.message.search_contract')}</span></div> `,'rgba(0,0,0,0)','#ffffff','rgba(0,0,0,.6)');

                 }
               },
               success: function (data) {
                   create_array_for_product('contract_code_number',data.code_number);
                   create_array_for_product('contract_code',data.code);

                   resolve(
                       {
                           data: data,
                           next: true}
                   )
               },
               error: function () {
               resolve({
                   next: false
               })
           }
           })
       }
   )
});

export let get_tariffs = (box_block = null) => new Promise((resolve, reject)=> {
    $.ajax({
        url: location.origin+'/tariff',
        data: {
            url: 'url_tariff_by_contracts',
            contract_code: create_array_for_product('contract_code_number','','show') || ''
        },
        beforeSend: function () {
            if(box_block !== null){
                blockElementsById(box_block,`<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t('global.main.form.message.search_tariff')}</span></div> `,'rgba(0,0,0,0)','#ffffff','rgba(0,0,0,.6)');

            }
        },
        success: function (tariffs_data) {
            create_array_for_product('tariff_code',tariffs_data.code)
            create_array_for_product('tariff_name',tariffs_data.name)

            resolve({
                data: tariffs_data,
                next: true
            })
        },
        error: function () {
            resolve({
                next: false
            })
        }
    })
})

export let get_modalities = (box_block = null,dealer,product,amount, save = true) => new Promise((resolve, reject)=> {
    preferences('url_modalities','url').then(
        modalities => {
            $.ajax({
                url: location.origin+'/modalities_by_dealer',
                data: {
                    dealer:  dealer,
                    product: product,
                    max_amount: amount
                },
                beforeSend: function () {
                    if(box_block !== null){
                        blockElementsById(box_block,`<div class="d-flex flex-column justify-content-center align-items-center"><span class="fa fa-spinner fa-spin"></span> <span>${I18n.t('global.main.form.message.search_modality')}</span></div> `,'rgba(0,0,0,0)','#ffffff','rgba(0,0,0,.6)');

                    }
                },
                success: function (modalities_data) {

                    if(save){
                        create_array_for_product('modality_code',modalities_data.code)
                        create_array_for_product('modalities_name',modalities_data.name)
                    }
                    // console.log(modalities_data);

                    resolve({
                        next: true,
                        data: modalities_data
                    })
                },
                error: function () {
                    resolve({
                        next: false
                    })
                }
            })
        }
    )
})

export default class extends Controller {

}