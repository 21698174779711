import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    }
  },
  "check_reservation": {
    "code_text": "Check booking",
    "no_search_params": "No search criteria specified",
    "reference_incorrect": "There is no booking with reference number: %{reference}",
    "reference_not_found": "We could not validate any my booking with reference number: %{reference}, for Email: %{email}"
  },
  "colors": {
    "bgColor": "Fondo",
    "bgColorSecondary": "Fondo secundario",
    "bgDarkColor": "Fondo Oscuro",
    "btnDisable": "Botón Disabled",
    "btnDisableOpacity50": "Botón Disabled con Opacidad al 50%",
    "btnError": "Botón Error",
    "btnErrorOpacity50": "Botón Error con Opacidad al 50%",
    "btnInfo": "Botón Info",
    "btnInfoOpacity50": "Botón Info con Opacidad al 50%",
    "btnSuccess": "Botón Success",
    "btnSuccessOpacity50": "Botón Success con Opacidad al 50%",
    "btnWarning": "Botón Warning",
    "btnWarningOpacity50": "Botón Warning con Opacidad al 50%",
    "sectionEven": "Sección par",
    "sectionOdd": "Sección inpar",
    "sectionSecondary": "Seccion alternativa",
    "txtAlt": "Texto Alterativo",
    "txtC": "Texto",
    "txtDsb": "Texto deshabilitado",
    "txtHighlight": "Primario",
    "txtHighlightOpacity30": "Primario con Opacidad al 30%",
    "txtHighlightOpacity50": "Primario con Opacidad al 50%",
    "txtHighlightOpacity80": "Primario con Opacidad al 80%",
    "txtHighlightSecondary": "Secundario",
    "txtHighlightSecondaryOpacity30": "Secundario con Opacidad al 30%",
    "txtHighlightSecondaryOpacity50": "Secundario con Opacidad al 50%",
    "txtHighlightSecondaryOpacity80": "Secundario con Opacidad al 80%"
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "errors_page": {
    "internal_server_error": "apparently there was an error on the server, please try again later",
    "not_found": "The requested page does not exist or the link may be broken"
  },
  "global": {
    "admin": {
      "ch": "Chinese",
      "de": "German",
      "en": "English",
      "es": "Spanish",
      "fr": "French",
      "it": "Italian",
      "pt": "Portuguese",
      "ru": "Russian"
    },
    "footer": {
      "contact": "Contact Us",
      "faqs": "Faq's",
      "information": "Information",
      "privacy_policy": "Privacy Policy and Use of Cookies",
      "term_and_conditions": "Terms and Conditions"
    },
    "in_maintenance": "We are under maintenance. We will be back soon",
    "loader": "Loading...",
    "main": {
      "form": {
        "button": "Search",
        "message": {
          "checking_availability": "Checking the availability of the requested service...",
          "create_shooping_cart": "Validating reservation",
          "load_products": "Load products",
          "no_availability": "There is no availability for the requested service.",
          "search_contract": "Looking for booking contract...",
          "search_modality": "Looking for reservation modality",
          "search_tariff": "Looking for booking fee"
        },
        "select_category": "Select a category",
        "select_destiny": "Select a destination",
        "select_office": "Select an office",
        "work_all_day": "This office works 24 hours.",
        "work_by_hours": "This office works from %{from} to %{to}"
      },
      "info": "View details",
      "read_more": "Read more..."
    },
    "online_asistan": "Online assistance",
    "popup": {
      "cancel_rent_cancel": "No, continue with my booking",
      "cancel_rent_confirm": "Yes, cancel",
      "create_shopping_cart_confirm": "I understand",
      "product_no_disponibility_confirm": "I understand",
      "verify_email_confirm": "Validate",
      "verify_email_in_process_confirm": "I understand",
      "verify_login_for_rent_cancel": "Cancel",
      "verify_login_for_rent_cancel_confirm": "I understand",
      "verify_login_for_rent_confirm": "Log in",
      "verify_login_for_rent_denied": "Register",
      "with_rent_cancel": "No, continue with the old",
      "with_rent_confirm": "Yes, I want to continue",
      "with_rent_in_pay_process_cancel": "I don't want to continue",
      "with_rent_in_pay_process_cancel_confirm": "I understand",
      "with_rent_in_pay_process_confirm": "Yes, I want to continue",
      "with_rent_redirect_to_pay_confirm": "Yes, I want to continue",
      "with_rent_redirect_to_paycancel": "No, Cancel the booking",
      "without_rent_confirm": "I understand"
    },
    "section_an_page": {
      "accreditation": "Accreditations available (%{count})",
      "back": "Back",
      "back_to_home": "Back to home",
      "events": "Events",
      "go_to_admin": "Go to administration",
      "notices": "Notices",
      "print": "Print",
      "show_accreditation": "Show accreditations"
    },
    "slogan": "TURNAT",
    "time": {
      "minutes": "Minutes",
      "seconds": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "histories": {
    "accreditation": {
      "accreditation_for_book": "Accreditations for reservation %{ref}",
      "accreditation_new": "New accreditation",
      "accreditation_save": "Save",
      "accreditation_save_and_continue": "Save and continue",
      "accreditation_save_and_send": "Save and send by mail",
      "accreditation_with_user_data": "Use my user data",
      "available": "Accreditations available (%{count})",
      "not_accreditation": "There are no accreditations to show.",
      "send_all": "Send all accreditations"
    },
    "check_reservation": {
      "address": "Address",
      "client": "Client",
      "comment": "Observations",
      "create_at": "Created",
      "days": "Days",
      "droppof_place": "Place of delivery",
      "email": "Email",
      "from": "From",
      "name_last_name": "Name and surnames",
      "pickup_place": "Pick up place",
      "price": "Price",
      "product": "Product",
      "product_name": "Name",
      "quantity": "Quantity",
      "reference_number": "No. Reference",
      "reservation_status": "Booking Status",
      "status_cancel": "Cancelled",
      "status_confirmation": "Confirmed",
      "status_in_confirmation": "In confirmation",
      "status_modify": "Modified",
      "to": "To",
      "total_price": "Total price"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ]
    },
    "transliterate": {
      "rule": {
        "Á": "A",
        "É": "E",
        "Í": "I",
        "Ñ": "N",
        "Ó": "O",
        "Ú": "U",
        "Ü": "U",
        "á": "a",
        "é": "e",
        "í": "i",
        "ñ": "n",
        "ó": "o",
        "ú": "u",
        "ü": "u"
      }
    }
  },
  "login_and_register": {
    "email_validate": {
      "invalid_token": "The provided token is not found or has expired, contact an administrator.",
      "request_validate_no_authenticated": "You must authenticate to be able to validate your email",
      "request_validate_token": "A validation message has been sent to <strong>%{email}</strong>, please follow the instructions to validate your account.<br><hr><small>If it doesn't appear in your inbox, please check your spam folder.</small>",
      "request_validate_token_error": "An error occurred while sending the mail, please try again later, if the error persists.<br> <strong>Contact an administrator</strong>",
      "title": "Email Validation",
      "validate_error": "An error has occurred and your account could not be validated, please try again later or contact an administrator.",
      "validate_successfully": "<p>Dear <strong>%{name}</strong></p> <br>Your account was successfully validated!!!",
      "validate_token": "<p>Dear <strong>%{name}</strong></p> <br>Your account was previously validated, no action is required."
    },
    "forgot_my_password": {
      "email": "Email",
      "helper": "Please enter your e-mail",
      "send_btn": "Send",
      "title": "I forgot my password"
    },
    "login": {
      "dont_have_account": "I don't have an account yet",
      "email": "Email",
      "entity": "Entity",
      "forgot_my_password": "I forgot my password",
      "login_message_error": "Wrong username or password",
      "password": "Password",
      "remember_me": "Remember me",
      "title": "Log in"
    },
    "password_reset": {
      "can_change_password": "You can change your password",
      "change_password": "Change Password",
      "change_password_invalid": "Invalid request.",
      "change_password_time_expire": "The time to change your password has expired.",
      "email_incorrect": "Failed to generate temporary token for password reset",
      "email_send_error": "We cannot guarantee that your email is on our site",
      "email_send_success": "An email has been sent with the data to reset your password",
      "password_change_error": "There was a problem changing the password, please try again.",
      "password_change_successfully": "Your password was changed successfully",
      "password_confirm_field": "Confirm Password",
      "password_field": "Password",
      "save_btn": "Change Password",
      "title": "Password change request"
    },
    "register": {
      "address": "Address",
      "checkbox": "Register and Login",
      "city": "City",
      "country": "Country",
      "email": "Email",
      "last_name": "Last name",
      "login_btn": "Go to Login",
      "name": "Name",
      "passport": "Passport or DNI",
      "password": "Password",
      "password_confirm": "Password confirm",
      "phone_numbre": "Phone number",
      "send_btn": "Register",
      "title": "New account"
    }
  },
  "mailer": {
    "email_validate": {
      "email_btn": "Validate email",
      "helper": "If you have any doubts or questions, simply contact us through the following email , it is always a pleasure to help",
      "text": "You provided an email to log into our system and it is necessary to validate that it is an active email, to validate your account, please click on the following button",
      "thanks": "Thanks for choosing our service",
      "title": "Dear %{user}"
    },
    "password_change": {
      "reset_btn": "Recover password",
      "text": "We are sending you this email because you requested a password change, to validate that it was you and change the password of your account, click on the following button",
      "title": "Dear %{user}"
    }
  },
  "main": {
    "body": {
      "catalog": {
        "add_to_cart": "Add to cart",
        "cancellation_time": "Time in advance to cancel: %{days} days",
        "description": "Description",
        "from": "From",
        "modality_description": "Description of the modality",
        "other_products": "Other products you may be interested",
        "price_tag": "Reference price from: %{price} EUR",
        "rent_btn": "Booking",
        "reservation_time": "Time in advance to reserve: %{days} days",
        "title": "Product catalog"
      },
      "read_more": "Read more..."
    },
    "cookies_box": {
      "button": "I agree",
      "description": "We temporarily store data to improve your browsing experience and recommend content of interest. By using our services, you agree to such tracking.",
      "title": "This website uses cookies"
    },
    "header": {
      "admin": "Administration",
      "check_reservation": {
        "button": "Check",
        "email": "E-mail",
        "no_reference": "Reference number",
        "title": "Check booking"
      },
      "commercial": "Commercial administration",
      "login": "Login",
      "logout": "Logout",
      "profile": "My profile",
      "register": "Register",
      "shopping_cart": {
        "accept_btn": "Continue",
        "cancel_btn": "Cancel",
        "category": "Category",
        "destiny": "Destiny",
        "drop_date": "Drop off date",
        "load_products": "Loading shopping cart products",
        "pick_date": "Pick up date",
        "price": "Price",
        "time_elapse": "Time left",
        "title": "Products in shopping cart"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pre_reservations": {
    "category": "Category",
    "product_description": "Product description"
  },
  "privacy_policy": {
    "title": "Privacy Policy"
  },
  "profile": {
    "my_reservations": {
      "last_reservations": "%{count} latest rentals",
      "price": "Price",
      "reference": "No. Reference",
      "status": "Status",
      "title": "My bookings",
      "type": "Type"
    },
    "profile": {
      "address": "Address",
      "avatar": "Avatar",
      "avatar_info_edit": "JPG or PNG no larger than 1MB",
      "city": "City",
      "country": "Country",
      "details": "Account details",
      "edit_btn": "Edit account details",
      "email": "Email (To access the site)",
      "email_invalid": "Validate",
      "email_valid": "Validated",
      "name": "Name (How your name will appear on the site)",
      "passport": "DNI or Passport",
      "phone": "Phone",
      "surname": "Surnames",
      "title": "Profile"
    },
    "profile_save_success": "Modified profile",
    "security": {
      "change_password": "Change password",
      "confirm_new_password": "Confirm new password",
      "new_password": "New password",
      "notice_password_change_error": "Wrong old password",
      "notice_password_change_success": "Changed password",
      "old_password": "Old password",
      "title": "Security"
    }
  },
  "reservation": {
    "main": {
      "action_btn": {
        "finish": "Pay",
        "next": "Next",
        "prev": "Previous"
      },
      "steps": {
        "four": "Details of payment",
        "one": "Titular driver",
        "three": "Terms and Conditions",
        "two": "Extra data"
      },
      "steps_body": {
        "four": {
          "checking_gateway": "Checking gateway status...",
          "payment_gateway": "Online payment gateway",
          "proccesing_pay": "Processing data...",
          "title": "Payment method"
        },
        "one": {
          "address": "Address",
          "city": "City",
          "country": "Country",
          "email": "Email",
          "name": "Names",
          "passport": "Passport",
          "surname": "Surnames"
        },
        "three": {
          "accept_terms": "I accept the terms and conditions"
        },
        "two": "Extra data"
      },
      "title": "Booking information"
    },
    "side_bar": {
      "active_reservation": "Booking",
      "add_product": "Add product",
      "add_product_mobile": "Add",
      "adults": "Adults",
      "amount": "Amount",
      "assistance": "Assistance",
      "brand": "Brand",
      "cancel_reservation_btn": "Cancel shopping cart",
      "cancel_reservation_mobile_btn": "Cancel",
      "category": "Category",
      "children": "Children",
      "confirm_cart": "Proceed to payment",
      "confirm_cart_mobile": "Pay",
      "date": "Date",
      "dealer": "Dealer",
      "delete_product": "Delete product",
      "delete_product_mobile": "Delete",
      "departure_from": "Departure from",
      "destiny": "Destiny",
      "dropoff_date": "Date of delivery",
      "dropoff_time": "Time of delivery",
      "event_type": "Event type",
      "lang": "Language",
      "modality": "Modality",
      "model": "Model",
      "payment_method": "Payment method",
      "person_amount": "Persons",
      "pickup_date": "Pick up date",
      "pickup_place": "Pick up at",
      "pickup_place_description": "Specify the place where you are or Pick-up point",
      "pickup_time": "Pick up time",
      "price": "Price",
      "price_by_person": "Price by person",
      "product": "Product",
      "time_elapse": "Time left",
      "total_price": "Total to pay",
      "update_product": "Update product",
      "update_product_mobile": "Update"
    }
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "will_paginate": {
    "container_aria_label": "Pagination",
    "next_label": "Next &#8594;",
    "page_aria_label": "Page %{page}",
    "page_entries_info": {
      "multi_page": "Displaying %{model} %{from} - %{to} of %{count} in total",
      "multi_page_html": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{count}</b> in total",
      "single_page": {
        "one": "Displaying 1 %{model}",
        "other": "Displaying all %{count} %{model}",
        "zero": "No %{model} found"
      },
      "single_page_html": {
        "one": "Displaying <b>1</b> %{model}",
        "other": "Displaying <b>all&nbsp;%{count}</b> %{model}",
        "zero": "No %{model} found"
      }
    },
    "page_gap": "&hellip;",
    "previous_label": "&#8592; Previous"
  }
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "La validación falló: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "No se puede eliminar el registro porque existen %{record} dependientes",
          "has_one": "No se puede eliminar el registro porque existe un %{record} dependiente"
        }
      }
    }
  },
  "check_reservation": {
    "code_text": "Chequear reserva",
    "no_search_params": "No se especificó ningun criterio de búsqueda",
    "reference_incorrect": "No hay ninguna reserva  con No. de referencia: %{reference}",
    "reference_not_found": "No pudimos validar ninguna reserva con No. de referencia: %{reference}, para el Email: %{email}"
  },
  "colors": {
    "bgColor": "Fondo",
    "bgColorSecondary": "Fondo secundario",
    "bgDarkColor": "Fondo Oscuro",
    "btnDisable": "Botón Disabled",
    "btnDisableOpacity50": "Botón Disabled con Opacidad al 50%",
    "btnError": "Botón Error",
    "btnErrorOpacity50": "Botón Error con Opacidad al 50%",
    "btnInfo": "Botón Info",
    "btnInfoOpacity50": "Botón Info con Opacidad al 50%",
    "btnSuccess": "Botón Success",
    "btnSuccessOpacity50": "Botón Success con Opacidad al 50%",
    "btnWarning": "Botón Warning",
    "btnWarningOpacity50": "Botón Warning con Opacidad al 50%",
    "sectionEven": "Sección par",
    "sectionOdd": "Sección inpar",
    "sectionSecondary": "Seccion alternativa",
    "txtAlt": "Texto Alterativo",
    "txtC": "Texto",
    "txtDsb": "Texto deshabilitado",
    "txtHighlight": "Primario",
    "txtHighlightOpacity30": "Primario con Opacidad al 30%",
    "txtHighlightOpacity50": "Primario con Opacidad al 50%",
    "txtHighlightOpacity80": "Primario con Opacidad al 80%",
    "txtHighlightSecondary": "Secundario",
    "txtHighlightSecondaryOpacity30": "Secundario con Opacidad al 30%",
    "txtHighlightSecondaryOpacity50": "Secundario con Opacidad al 50%",
    "txtHighlightSecondaryOpacity80": "Secundario con Opacidad al 80%"
  },
  "date": {
    "abbr_day_names": [
      "dom",
      "lun",
      "mar",
      "mié",
      "jue",
      "vie",
      "sáb"
    ],
    "abbr_month_names": [
      null,
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic"
    ],
    "day_names": [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado"
    ],
    "formats": {
      "default": "%-d/%-m/%Y",
      "long": "%-d de %B de %Y",
      "short": "%-d de %b"
    },
    "month_names": [
      null,
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "alrededor de 1 hora",
        "other": "alrededor de %{count} horas"
      },
      "about_x_months": {
        "one": "alrededor de 1 mes",
        "other": "alrededor de %{count} meses"
      },
      "about_x_years": {
        "one": "alrededor de 1 año",
        "other": "alrededor de %{count} años"
      },
      "almost_x_years": {
        "one": "casi 1 año",
        "other": "casi %{count} años"
      },
      "half_a_minute": "medio minuto",
      "less_than_x_minutes": {
        "one": "menos de 1 minuto",
        "other": "menos de %{count} minutos"
      },
      "less_than_x_seconds": {
        "one": "menos de 1 segundo",
        "other": "menos de %{count} segundos"
      },
      "over_x_years": {
        "one": "más de 1 año",
        "other": "más de %{count} años"
      },
      "x_days": {
        "one": "1 día",
        "other": "%{count} días"
      },
      "x_minutes": {
        "one": "1 minuto",
        "other": "%{count} minutos"
      },
      "x_months": {
        "one": "1 mes",
        "other": "%{count} meses"
      },
      "x_seconds": {
        "one": "1 segundo",
        "other": "%{count} segundos"
      },
      "x_years": {
        "one": "1 año",
        "other": "%{count} años"
      }
    },
    "prompts": {
      "day": "Día",
      "hour": "Hora",
      "minute": "Minuto",
      "month": "Mes",
      "second": "Segundo",
      "year": "Año"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "debe ser aceptado",
      "blank": "no puede estar en blanco",
      "confirmation": "no coincide",
      "empty": "no puede estar vacío",
      "equal_to": "debe ser igual a %{count}",
      "even": "debe ser par",
      "exclusion": "está reservado",
      "greater_than": "debe ser mayor que %{count}",
      "greater_than_or_equal_to": "debe ser mayor que o igual a %{count}",
      "inclusion": "no está incluido en la lista",
      "invalid": "no es válido",
      "less_than": "debe ser menor que %{count}",
      "less_than_or_equal_to": "debe ser menor que o igual a %{count}",
      "model_invalid": "La validación falló: %{errors}",
      "not_a_number": "no es un número",
      "not_an_integer": "debe ser un entero",
      "odd": "debe ser impar",
      "other_than": "debe ser distinto de %{count}",
      "present": "debe estar en blanco",
      "required": "debe existir",
      "taken": "ya está en uso",
      "too_long": {
        "one": "es demasiado largo (1 carácter máximo)",
        "other": "es demasiado largo (%{count} caracteres máximo)"
      },
      "too_short": {
        "one": "es demasiado corto (1 carácter mínimo)",
        "other": "es demasiado corto (%{count} caracteres mínimo)"
      },
      "wrong_length": {
        "one": "no tiene la longitud correcta (1 carácter exactos)",
        "other": "no tiene la longitud correcta (%{count} caracteres exactos)"
      }
    },
    "template": {
      "body": "Se encontraron problemas con los siguientes campos:",
      "header": {
        "one": "No se pudo guardar este/a %{model} porque se encontró 1 error",
        "other": "No se pudo guardar este/a %{model} porque se encontraron %{count} errores"
      }
    }
  },
  "errors_page": {
    "internal_server_error": "al parecer hubo un error en el servidor, intentelo mas tarde",
    "not_found": "La página solicitada no existe o puede que el enlace este roto"
  },
  "global": {
    "admin": {
      "ch": "Chino",
      "de": "Alemán",
      "en": "Ingles",
      "es": "Español",
      "fr": "Frances",
      "it": "Italiano",
      "pt": "Portugues",
      "ru": "Ruso"
    },
    "footer": {
      "contact": "Contacto",
      "faqs": "Preguntas frecuentes",
      "information": "Información",
      "privacy_policy": "Política de Privacidad y Uso de Cookies",
      "term_and_conditions": "Términos y condiciones"
    },
    "in_maintenance": "Estamos en mantenimiento. Volveremos pronto",
    "loader": "Cargando...",
    "main": {
      "form": {
        "button": "Buscar",
        "message": {
          "checking_availability": "Comprobando la disponibilidad del servicio solicitado...",
          "create_shooping_cart": "Validando datos de la reserva...",
          "load_products": "Cargando productos",
          "no_availability": "No hay disponibilidad para el servicio solicitado.",
          "search_contract": "Buscando contrato de reserva...",
          "search_modality": "Buscando modalidad de reserva...",
          "search_tariff": "Buscando tarifa de reserva..."
        },
        "select_category": "Seleccione una categoría",
        "select_destiny": "Seleccione un destino",
        "select_office": "Seleccione una oficina",
        "work_all_day": "Esta oficina trabaja 24 horas.",
        "work_by_hours": "Esta oficina trabaja en el horario de %{from} a %{to} "
      },
      "info": "Ver detalles",
      "read_more": "Leer más..."
    },
    "online_asistan": "Asistencia en linea",
    "popup": {
      "cancel_rent_cancel": "No, continuar con mi reserva",
      "cancel_rent_confirm": "Si, cancelar",
      "create_shopping_cart_confirm": "Entendido",
      "product_no_disponibility_confirm": "Entendido",
      "verify_email_confirm": "Validar",
      "verify_email_in_process_confirm": "Entendido",
      "verify_login_for_rent_cancel": "Cancelar",
      "verify_login_for_rent_cancel_confirm": "Entendido",
      "verify_login_for_rent_confirm": "Iniciar sesión",
      "verify_login_for_rent_denied": "Registrarme",
      "with_rent_cancel": "No, continuar con el antiguo",
      "with_rent_confirm": "Si, deseo continuar",
      "with_rent_in_pay_process_cancel": "No deseo continuar",
      "with_rent_in_pay_process_cancel_confirm": "Entendido",
      "with_rent_in_pay_process_confirm": "Si, deseo continuar",
      "with_rent_redirect_to_pay_confirm": "Si, deseo continuar",
      "with_rent_redirect_to_paycancel": "No, Cancelar la reserva",
      "without_rent_confirm": "Entendido"
    },
    "section_an_page": {
      "accreditation": "Acreditaciones disponibles (%{count})",
      "back": "Regresar",
      "back_to_home": "Regresar al inicio",
      "events": "Eventos",
      "go_to_admin": "Ir a la administración",
      "notices": "Noticias",
      "print": "Imprimir",
      "show_accreditation": "Ver acreditaciones"
    },
    "slogan": "TURNAT",
    "time": {
      "minutes": "Minutos",
      "seconds": "Segundos"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Por favor seleccione"
    },
    "submit": {
      "create": "Crear %{model}",
      "submit": "Guardar %{model}",
      "update": "Actualizar %{model}"
    }
  },
  "histories": {
    "accreditation": {
      "accreditation_for_book": "Acreditaciones para la reserva %{ref}",
      "accreditation_new": "Nueva acreditación",
      "accreditation_save": "Acreditar",
      "accreditation_save_and_continue": "Acreditar y continuar",
      "accreditation_save_and_send": "Acreditar y enviar por correo",
      "accreditation_with_user_data": "Usar mis datos de usuario",
      "available": "Acreditaciones disponibles (%{count})",
      "not_accreditation": "No hay acreditaciones para mostrar.",
      "send_all": "Enviar todas las acreditaciones"
    },
    "check_reservation": {
      "address": "Dirección",
      "client": "Cliente",
      "comment": "Observaciones",
      "create_at": "Creado",
      "days": "Días",
      "droppof_place": "Lugar de entrega",
      "email": "Correo electrónico",
      "from": "Fecha",
      "name_last_name": "Nombre(s) y Apellidos",
      "pickup_place": "Lugar de recogida",
      "price": "Precio",
      "product": "Producto",
      "product_name": "Nombre",
      "quantity": "Cantidad",
      "reference_number": "No. Referencia",
      "reservation_status": "Estado de la reserva",
      "status_cancel": "Cancelada",
      "status_confirmation": "Confirmada",
      "status_in_confirmation": "En confirmación",
      "status_modify": "Modificada",
      "to": "Hasta",
      "total_price": "Precio total"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ]
    },
    "transliterate": {
      "rule": {
        "Á": "A",
        "É": "E",
        "Í": "I",
        "Ñ": "N",
        "Ó": "O",
        "Ú": "U",
        "Ü": "U",
        "á": "a",
        "é": "e",
        "í": "i",
        "ñ": "n",
        "ó": "o",
        "ú": "u",
        "ü": "u"
      }
    }
  },
  "login_and_register": {
    "email_validate": {
      "invalid_token": "El token proporcionado no se encuentra o ha expirado, contacto con un administrador.",
      "request_validate_no_authenticated": "Debe de autenticarse para poder validar su correo",
      "request_validate_token": "Se le ha enviado un mensaje de validación al correo <strong>%{email}</strong>, por favor siga las instrucciones del mismo para validar su cuenta.<br><hr><small>Si no aparece en su bandeja de entrada, revise la carpeta de spam.</small>",
      "request_validate_token_error": "Ha ocurrido un error al enviar el correo, intentelo mas tarde, si el error persiste.<br> <strong>Contacte con un administrador</strong>",
      "title": "Validación de Email",
      "validate_error": "Ha ocurrido un error y su cuenta no se ha podido validar, intentelo más tarde o pongase en contacto con un administrador.",
      "validate_successfully": "<p>Estimado(a) <strong>%{name}</strong></p><br>Su cuenta fue validada correctamente!!!",
      "validate_token": "<p>Estimado(a) %{name}. <br>Su cuenta fue validada anteriormente, no es necesario realizar la accción.</p>"
    },
    "forgot_my_password": {
      "email": "Correo electrónico",
      "helper": "Por favor introduzca su dirección de correo",
      "send_btn": "Enviar",
      "title": "Olvide mi contraseña"
    },
    "login": {
      "dont_have_account": "No tengo una cuenta aún",
      "email": "Correo electrónico",
      "entity": "Entidad",
      "forgot_my_password": "Olvide mi contraseña",
      "login_message_error": "Usuario o Contraseña incorrecto",
      "password": "Contraseña",
      "remember_me": "Recuerdame",
      "title": "Iniciar sesión"
    },
    "password_reset": {
      "can_change_password": "Puede cambiar su contraseña",
      "change_password": "Cambiar contraseña",
      "change_password_invalid": "Solicitud invalida.",
      "change_password_time_expire": "Ha expirado el tiempo para cambiar su contraseña.",
      "email_incorrect": "Error al generar el token temporal para el reestablecimeinto de su contraseña",
      "email_send_error": "No podemos avalar que su correo se encuentre en nuestro sitio",
      "email_send_success": "Se a enviado un correo con los datos para reestabler su contraseña",
      "password_change_error": "Ocurrio un problema al cambiar la contraseña, intente de nuevo.",
      "password_change_successfully": "Su contraseña fue modificada correctamente",
      "password_confirm_field": "Confirmar Contraseña",
      "password_field": "Contraseña",
      "save_btn": "Cambiar contraseña",
      "title": "Solicitud de cambio de contraseña"
    },
    "register": {
      "address": "Dirección",
      "checkbox": "Registrarme e Iniciar sesión",
      "city": "Ciudad",
      "country": "País",
      "email": "Correo electrónico",
      "last_name": "Apellidos",
      "login_btn": "Ir a Iniciar sesión",
      "name": "Nombre",
      "passport": "CI o Pasaporte",
      "password": "Contraseña",
      "password_confirm": "Confirmar contraseña",
      "phone_numbre": "Télefono",
      "send_btn": "Registrarme",
      "title": "Registrarme"
    }
  },
  "mailer": {
    "email_validate": {
      "email_btn": "Validar coreo electrónico",
      "helper": "Si tiene alguna pregunta, simplemente contactenos a través del siguiente correo , siempre es un placer ayudar",
      "text": "Usted proporciono un correo electrónico para iniciar sesión en nuestro sistema y es necesario validar que es un correo electrónico activo, para validar su cuenta, por favor haga click en el siguiente botón",
      "thanks": "Gracias por prefereir nuestros servicios",
      "title": "Estimado(a) %{user}"
    },
    "password_change": {
      "reset_btn": "Recuperar contraseña",
      "text": "Le enviamos este correo porque usted solicito un cambio de contraseña, para validar que ha sido usted y cambiar la contraseña de su cuenta haga click en el siguiente botón",
      "title": "Estimado(a) %{user}"
    }
  },
  "main": {
    "body": {
      "catalog": {
        "add_to_cart": "Adicionar al carrito",
        "cancellation_time": "Tiempo de antelación para cancelar: %{days} días",
        "description": "Descripción",
        "from": "Desde",
        "modality_description": "Descripción de la modalidad",
        "other_products": "Otros productos que puedan ser de tu interes",
        "price_tag": "Precio referencial desde: %{price} EUR",
        "rent_btn": "Reservar",
        "reservation_time": "Tiempo de antelación para reservar: %{days} días",
        "title": "Catalogo de productos"
      },
      "read_more": "Leer más..."
    },
    "cookies_box": {
      "button": "Estoy de acuerdo",
      "description": "Almacenamos datos temporalmente para mejorar su experiencia de navegación y recomendarle contenido de interés. Al utilizar nuestros servicios, aceptas dicho seguimiento.",
      "title": "Este sitio web usa cookies"
    },
    "header": {
      "admin": "Administración",
      "check_reservation": {
        "button": "Chequear",
        "email": "Correo electrónico",
        "no_reference": "No. de referencia",
        "title": "Chequear reserva"
      },
      "commercial": "Administración comercial",
      "login": "Acceder",
      "logout": "Salir",
      "profile": "Mi perfil",
      "register": "Registrarse",
      "shopping_cart": {
        "accept_btn": "Continuar",
        "cancel_btn": "Cancelar",
        "category": "Categoría",
        "destiny": "Destino",
        "drop_date": "Fecha de entrega",
        "load_products": "Cargando productos del carrito",
        "pick_date": "Fecha de recogida",
        "price": "Precio",
        "time_elapse": "Tiempo restante",
        "title": "Producto en carrito"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 3,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "mil millones",
          "million": {
            "one": "millón",
            "other": "millones"
          },
          "quadrillion": "mil billones",
          "thousand": "mil",
          "trillion": {
            "one": "billón",
            "other": "billones"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n %"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pre_reservations": {
    "category": "Categoría",
    "product_description": "Descripción del producto"
  },
  "privacy_policy": {
    "title": "Política de Privacidad"
  },
  "profile": {
    "my_reservations": {
      "last_reservations": "%{count} últimas rentas",
      "price": "Precio",
      "reference": "No. Referencia",
      "status": "Estado",
      "title": "Mis reservaciones",
      "type": "Tipo"
    },
    "profile": {
      "address": "Dirección",
      "avatar": "Avatar",
      "avatar_info_edit": "JPG or PNG no mayor de 1MB",
      "city": "Ciudad",
      "country": "País",
      "details": "Detalles de la cuenta",
      "edit_btn": "Editar detalles de la cuenta",
      "email": "Correo electrónico (Para acceder al sitio)",
      "email_invalid": "Validar",
      "email_valid": "Validado",
      "name": "Nombre (Cómo aparecerá su nombre en el sitio)",
      "passport": "CI o Pasaporte",
      "phone": "Teléfono",
      "surname": "Apellidos",
      "title": "Perfil"
    },
    "profile_save_success": "Perfil modificado",
    "security": {
      "change_password": "Cambiar contraseña",
      "confirm_new_password": "Confirmar nueva contraseña",
      "new_password": "Nueva contraseña",
      "notice_password_change_error": "Contraseña anterior incorrecta",
      "notice_password_change_success": "Contraseña modificada",
      "old_password": "Contraseña anterior",
      "title": "Seguridad"
    }
  },
  "reservation": {
    "main": {
      "action_btn": {
        "finish": "Pagar",
        "next": "Siguiente",
        "prev": "Anterior"
      },
      "steps": {
        "four": "Datos del Pago",
        "one": "Conductor titular",
        "three": "Términos y condiciones",
        "two": "Datos extra"
      },
      "steps_body": {
        "four": {
          "checking_gateway": "Verificando estado de pasarela...",
          "payment_gateway": "Pasarela de pago en linea",
          "proccesing_pay": "Procesando datos...",
          "title": "Método de pago"
        },
        "one": {
          "address": "Dirección",
          "city": "Ciudad",
          "country": "País",
          "email": "Correo electrónico",
          "name": "Nombre(s)",
          "passport": "Pasaporte",
          "surname": "Apellidos"
        },
        "three": {
          "accept_terms": "Acepto los términos y condiciones"
        },
        "two": "Datos extras"
      },
      "title": "Datos de la reserva"
    },
    "side_bar": {
      "active_reservation": "Reserva",
      "add_product": "Adicionar producto",
      "add_product_mobile": "Adicionar",
      "adults": "Adultos",
      "amount": "Cantidad",
      "assistance": "Asistencia",
      "brand": "Marca",
      "cancel_reservation_btn": "Cancel shopping cart",
      "cancel_reservation_mobile_btn": "Cancel",
      "category": "Categoría",
      "children": "Niños",
      "confirm_cart": "Proceder al pago",
      "confirm_cart_mobile": "Pagar",
      "date": "Fecha",
      "dealer": "Prestatario",
      "delete_product": "Eliminar producto",
      "delete_product_mobile": "Eliminar",
      "departure_from": "Salida desde",
      "destiny": "Destino",
      "dropoff_date": "Fecha de entrega",
      "dropoff_time": "Hora de entrega",
      "event_type": "Tipo de evento",
      "lang": "Idioma",
      "modality": "Modalidad",
      "model": "Modelo",
      "payment_method": "Método de pago",
      "person_amount": "Personas",
      "pickup_date": "Fecha de recogida",
      "pickup_place": "Recogida en",
      "pickup_place_description": "Especifique lugar en que se encuentra ó Punto de recogida",
      "pickup_time": "Hora de recogida",
      "price": "Precio",
      "price_by_person": "Precio x persona",
      "product": "Producto",
      "time_elapse": "Tiempo restante",
      "total_price": "Total a pagar",
      "update_product": "Actualizar producto",
      "update_product_mobile": "Actualizar"
    }
  },
  "support": {
    "array": {
      "last_word_connector": " y ",
      "two_words_connector": " y ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%A, %-d de %B de %Y %H:%M:%S %z",
      "long": "%-d de %B de %Y %H:%M",
      "short": "%-d de %b %H:%M"
    },
    "pm": "pm"
  }
});
